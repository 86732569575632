import React, { useContext, useEffect, useState } from "react";
import Popover from "@mui/material/Popover";
import CloseIcon from "@mui/icons-material/Close";
import IosShareIcon from "@mui/icons-material/IosShare";
import { makeStyles } from "@material-ui/core";
import ButtonPrimary from "../ButtonPrimary/ButtonPrimary";
import ButtonSecondary from "../buttons/ButtonSecondary";
import InputGetLife from "../inputGetLife/InputGetLife";
import TextArea from "../textArea/TextArea";
import "./modalSendEmail.scss";
import { getValidateEmail } from "../../../utils/staticData";
import { I18nContext } from "../../../contexts/i18n.context";

const useStyles = makeStyles((theme) => ({
  popoverRoot: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(100, 100, 100, 0.80)",
  },
}));

export default function ModalSendEmail(props) {
  const { showModal, setShowModal, sendEmail, emailToSend } = props;

  const {
    state: { translate },
  } = useContext(I18nContext);

  const classes = useStyles();

  const [email, setEmail] = useState(emailToSend);
  const [text, setText] = useState("");

  useEffect(() => {
    if (!email) {setEmail(getValidateEmail(emailToSend))}  
  }, [emailToSend]);

  return (
    <Popover
      open={showModal}
      onClose={() => setShowModal(false)}
      anchorReference={"none"}
      classes={{
        root: classes.popoverRoot,
      }}
    >
      <div className="modalSendEmail">
        <div className="header">
          <div className="tittle">
            <h2>{translate("modal.sendPdf.title")}</h2>
            <IosShareIcon className="shareIcon" />
          </div>
          <div className="close-box">
            <CloseIcon
              onClick={() => setShowModal(false)}
              className="closeIcon"
            />
          </div>
        </div>
        <div className="box-info-send">
          <InputGetLife
            handleValues={(e) => setEmail(e)}
            type="email"
            answer={email}
            label={translate("modal.sendPdf.label.email")}
            placeholder={translate("modal.sendPdf.placeholder.email")}
          ></InputGetLife>
          <TextArea
            label={translate("modal.sendPdf.label.message")}
            placeholder={translate("modal.sendPdf.placeholder.message")}
            answer={text}
            handleValues={(e) => setText(e)}
          />
        </div>
        <div className="button-container-modal-send">
          <ButtonSecondary
            content={translate("modal.sendPdf.button.cancel")}
            className="upsell-personalization-add-button"
            handleChange={() => setShowModal(false)}
          />
          <ButtonPrimary
            content={translate("modal.sendPdf.button.send")}
            className="upsell-personalization-add-button"
            onClick={() => sendEmail(email, text)}
            disabled={!email}
          />
        </div>
      </div>
    </Popover>
  );
}
