import Typography from "../../atoms/Typography/Typography.component";
import * as React from "react";
import "./ContactBubble.style.scss";

export type ContactBubbleType = "phone" | "email";

interface ContactBubbleProps {
  title: string;
  text: string;
  contact: { text?: string; type: ContactBubbleType };
  variantTitle?: "paragraph" | "bold";
  variantText?: "paragraph" | "h5";
}

const hrefTypes = {
  email: "mailto",
  phone: "tel",
};

const ContactBubble: React.FC<ContactBubbleProps> = ({
  title,
  text,
  contact,
  variantTitle = "bold",
  variantText = "paragraph",
}): JSX.Element => {
  return (
    <div className="bubbleBlock">
      <Typography variant={variantTitle} text={title} />
      <div className="textBubble">
        <Typography variant={variantText} text={text} />
        <div className="contactBubble">
          <Typography variant={variantText} text={""}>
            <a href={`${hrefTypes[contact.type]}:${contact?.text}`}>
              {contact.text}
            </a>
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default ContactBubble;
