import { ROLES } from "../../../utils/roles";

// eslint-disable-next-line import/no-anonymous-default-export
export const buttonsES = [
  {
    type: "button",
    text: "Email",
    ref: "email",
    elementType: "button",
  },
  {
    type: "button",
    text: "Incidencia",
    ref: "incidencia",
    elementType: "button",
  },
  {
    type: "button",
    text: "Descargar",
    ref: "descargar",
    elementType: "button",
  },
];

export const buttonsFR = [
  {
    type: "button",
    text: "Email",
    ref: "email",
    elementType: "button",
  },
  {
    type: "button",
    text: "Descargar",
    ref: "descargar",
    elementType: "button",
  },
];


export const columns_policies = [
  {
    type: "cell",
    header: "Nº Póliza",
    elementType: "text",
    ref: "policyNumber",
  },
  {
    type: "cell",
    header: "Nombre completo",
    elementType: "text",
    ref: "name",
  },
  {
    type: "cell",
    header: "Estado",
    elementType: "text",
    ref: "status",
    sortable: true,
    class: "greeny",
  },
  {
    type: "cell",
    header: "Fecha compra",
    elementType: "text",
    ref: "purchaseDate",
    sortable: true,
  },
  {
    type: "cell",
    header: "Fecha de efecto",
    elementType: "text",
    ref: "effectDate",
    sortable: true,
  },
  {
    type: "cell",
    header: "Prima anual",
    elementType: "text",
    ref: "yearlyPrice",
  },
  {
    type: "cell",
    header: "Teléfono",
    elementType: "text",
    ref: "phone",
  },
];

export const columns_policies_admin = [
  ...columns_policies,
  {
    type: "cell",
    header: "Propietario",
    elementType: "text",
    ref: "owner",
  },
];

export const permission = [ROLES.SUPER_ADMIN, ROLES.ADMIN];
