import React, { useEffect, useState } from "react";

import "../../Rebranding/atoms/Input/InputBase.styles.scss";

export default function InputGetLife(props) {
  const {
    answer,
    basicUnity,
    contentType,
    disabled,
    error,
    errorMessage,
    readonly,
    handleValues,
    id,
    label,
    max,
    min,
    name,
    onFocus,
    placeholder,
    onBlurGreyBackground,
    onKeyDown,
    onKeyUp,
    setIsAddDisabled,
  } = props;
  const [actualStyle, setActualStyle] = useState("inputGetLife-input");
  const [errorInput, setErrorInput] = useState({ error, errorMessage });

  useEffect(() => {
    if (answer < min) {
      setErrorInput({
        error: true,
        errorMessage: `El valor debe ser mayor o igual de ${min} ${basicUnity}`,
      });
      setIsAddDisabled(true);
    } else if (answer > max) {
      setErrorInput({
        error: true,
        errorMessage: `El valor debe ser menor o igual de ${max} ${basicUnity}`,
      });
      setIsAddDisabled(true);
    } else {
      setErrorInput({
        error,
        errorMessage,
      });
    }
  }, [answer]);

  return (
    <div className="inputGetLife-container">
      {label && (
        <label
          className={
            disabled ? "inputGetLife-labelAnswered" : "inputGetLife-label"
          }
        >
          {label}
        </label>
      )}
      <input
        name={name}
        style={{ marginBottom: error ? "unset" : "" }}
        className={
          error === true
            ? "inputGetLife-input-error"
            : disabled === true
            ? "inputGetLife-onBlurGrey"
            : actualStyle
        }
        disabled={disabled}
        readOnly={readonly === true ? true : false}
        value={answer}
        onChange={handleValues ? (e) => handleValues(e.target.value) : null}
        type={contentType}
        id={id}
        placeholder={placeholder}
        onFocus={() => (onFocus ? onFocus() : null)}
        onBlur={(e) =>
          onBlurGreyBackground
            ? setActualStyle("inputGetLife-onBlurGrey")
            : props.onBlur
            ? props.onBlur(e)
            : null
        }
        onKeyDown={!errorInput.error && onKeyDown}
        onKeyUp={!errorInput.error && onKeyUp}
        min={min}
        max={max}
      />
      {errorInput.error === true && (
        <div className="inputGetLife-errorMessageContainer">
          <p
            style={{
              fontSize: "12px",
              color: "red",
              paddingTop: "1px",
            }}
          >
            {errorInput.errorMessage
              ? errorInput.errorMessage
              : "Introduce un valor correcto"}
          </p>
        </div>
      )}
    </div>
  );
}
