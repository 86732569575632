import { months } from "./staticData";

const prepareListMonthsOptions = function (translate) {
  const optionsMonths = [];
  months.forEach((element, key) =>
    optionsMonths.push({ value: String(key + 1), label: translate(`calculator.month.${element}`) })
  );

  return optionsMonths;
};

export default prepareListMonthsOptions;
