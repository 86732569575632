import React from "react";
import GroupIcons from "../../atoms/Icons/GroupIcons.component";
import StarIcon from "../../atoms/Icons/Star.component";
import Typography from "../../atoms/Typography/Typography.component";
import "./ClientOpinion.styles.scss";

interface ClientOpinionProps {
    name: string;
    text: string;
    variantName: 
    | "h4"
    | "h5"
    | "h6";
    variantText: 
    | "paragraph"
    | "bold";
}

const ClientOpinion: React.FC<ClientOpinionProps> = ({name, text, variantName, variantText}) => {

    const renderIcons = [<StarIcon/>,<StarIcon/>,<StarIcon/>,<StarIcon/>,<StarIcon/>]

    return (
        <article className="clientOpinion">
            <GroupIcons icons={renderIcons} className="clientOpinion--icons"/>
            <Typography variant={variantName} text={name} className="clientOpinion--name"/>
            <Typography variant={variantText} text={text} className="clientOpinion--text"/>

        </article> 
    )
}

export default ClientOpinion;