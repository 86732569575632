// Vendors
import { useContext, useState } from "react";
import { intersection } from "lodash";
import { useNavigate } from "react-router-dom";
// Api
import apiDownloadPdfTemplates from "../../../api/request/apiDownloadPdfTemplates";
// Cpmtext
import { ContextGetlife } from "../../../contexts/ContextGetlife";
import { I18nContext } from "../../../contexts/i18n.context";
// Utils
import {
  buttonsES,
  buttonsFR,
  columns_policies,
  columns_policies_admin,
  permission,
} from "../constants/Policies.constants";
import { fetchData } from "../../../utils/fetchBrokerTables";

const DOMAIN = process.env.REACT_APP_DOMAIN_LOCALE;

const TEMPLATE_DOC =
(DOMAIN === "es" || DOMAIN === "pt" || DOMAIN === "it")
    ? "contract"
    : "certificate-adhesion";

const PoliciesController = () => {
  const navigate = useNavigate();
  const { setEmail, rol, token } = useContext(ContextGetlife);
  const [data, setData] = useState();

  const {
    state: { translate },
  } = useContext(I18nContext);

  const handleGetPdf = async (leadId) => {
    const template = [TEMPLATE_DOC];
    await apiDownloadPdfTemplates(leadId, template).then((response) => {
      const { pdfUrl } = response;
      if (typeof pdfUrl !== "undefined" && pdfUrl.length > 0) {
        window.open(pdfUrl);
      }
    });
  };

  const handlebutton = ({ ref, project }) => {
    if (ref === "email") {
      window.open(`mailto:${project.email}`);
    } else if (ref === "incidencia") {
      setEmail(project.email);
      navigate("/incidents/newticket");
    } else {
      handleGetPdf(project.leadId);
    }
  };
  const fetchPolicies = async (params) => {
    await fetchData({ ...params, setData, token, translate });
  };

  //--------------------------------------------------------------------------------------//
  //                                                                                      //
  //                                       CONFIGS                                        //
  //                                                                                      //
  //--------------------------------------------------------------------------------------//

  const buttons = (DOMAIN === "es" || DOMAIN === "pt" || DOMAIN === "it") ? buttonsES : buttonsFR;

  const tableProps = {
    columns: intersection(permission, rol).length
      ? [...columns_policies_admin, ...buttons]
      : [...columns_policies, ...buttons],
    data,
    fetch: fetchPolicies,
    handlebutton: handlebutton,
    noDataTitle: translate("policies.noData.title"),
    noDataButtonText: translate("policies.noData.buttonText"),
    placeholder: translate("policies.search.placeholder"),
    placeholderDateRange: translate("policies.search.placeholderDateRange"),
    textType: translate("policies.header.subtitle"),
    title: translate("policies.header.title"),
    translate: translate,
    type: "policies",
  };

  return {
    tableProps,
  };
};
export default PoliciesController;
