import { useState, useContext } from "react";
import { IbanElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { useNavigate } from "react-router";
import * as FullStory from '@fullstory/browser';
import ButtonPrimary from "../../components/ButtonPrimary/ButtonPrimary";
import CustomRadio from "../../components/CustomRadio/CustomRadio";
import apiPaymentMethod from "../../../api/request/apiPaymentMethod";
import apiPaymentFinish from "../../../api/request/apiPaymentFinish";
import HttpTrackingRepository from "../../../api/request/Tracking/tracking.service";
import { GoogleContext } from "../../../contexts/GoogleTagManager.context";
import { GUARANTEE, formatPriceTwoDecimals } from "../../../utils/staticData";
import { getTrackingUtms } from "../../../api/request/apiLeadTrackingGet";

const SepaPayment = (props) => {
  const {
    clientSecret,
    setupIntent,
    leadId,
    token,
    brokerId,
    productCard,
    broker, translate
  } = props;

  const [acceptConditions, setAcceptConditions] = useState("");
  const textButton = translate("payment.button.pay");
  const [moreConditions, setMoreConditions] = useState(false);
  const [loading, setLoading] = useState(false);
  const stripe = useStripe();
  const elements = useElements();

  const { handleTrackerQuestion } = useContext(GoogleContext);
  const navigate = useNavigate();

  const trackingRepository = new HttpTrackingRepository(token);

  const handleAcceptConditions = (e) => {
    setAcceptConditions(e);
  };

  const handleSubmit = async () => {
    let eventDetails = [
      {
        leadId: leadId,
        paymentMethod: "SEPA",
        token: token,
        brokerEmployeeId: brokerId,
      },
    ];

    trackingRepository.add({ eventType: "PAYMENT_TRY", eventDetails });

    if (!stripe || !elements) {
      eventDetails = [
        {
          leadId: leadId,
          paymentMethod: "SEPA",
          errorMessage: "INTENT_NOT_LOADED",
          token: token,
          brokerEmployeeId: brokerId,
        },
      ];
      trackingRepository.add({ eventType: "PAYMENT_ERROR", eventDetails });
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setLoading(true);

    const result = await stripe.confirmSepaDebitSetup(clientSecret, {
      payment_method: {
        sepa_debit: elements.getElement(IbanElement),
        billing_details: {
          name: setupIntent.name + " " + setupIntent.lastName,
          email: setupIntent.email,
        },
      },
    });
    if (result.error) {
      eventDetails = [
        {
          leadId: leadId,
          paymentMethod: "SEPA",
          errorMessage: result.error,
          token: token,
          brokerEmployeeId: brokerId,
        },
      ];
      trackingRepository.add({ eventType: "PAYMENT_ERROR", eventDetails });
      setLoading(false);
    } else {
      const paymentMethodData = {
        payment_method: result.setupIntent.payment_method,
        setupIntent: result.setupIntent.id,
        leadId: leadId,
        type: "sepa_debit",
      };
      await apiPaymentMethod(token, paymentMethodData).then(async (res) => {
        if (res === "OK") {
          await apiPaymentFinish(token, leadId).then(async (status) => {
            if (
              status.status === "succeeded" ||
              status.status === "processing"
            ) {
              eventDetails = [
                {
                  leadId: leadId,
                  token: token,
                  brokerEmployeeId: brokerId,
                },
              ];
              trackingRepository.add({
                eventType: "PAYMENT_SUCCESS",
                eventDetails,
              });
              const utms = await getTrackingUtms(leadId);
              const eventData = {
                leadId: leadId,
                finalCapital: productCard.capital,
                coverage: GUARANTEE[productCard.insuranceType],
                calculatedPremium: productCard.monthlyPrice,
                intent: productCard.intention,
                page: window.location.href,
                paymentMethod: paymentMethodData.type,
                brokerEmployeeId: brokerId,
                brokerId: broker.brokerageId,
                platform: "Broker",
                discountedPremium: productCard.promotion ? parseFloat(formatPriceTwoDecimals(productCard.promotion.price)) : parseFloat(formatPriceTwoDecimals(productCard.monthlyPrice)),
                paymentPeriod: productCard.period ? productCard.period : "monthly",
                policyId: leadId,
                ...utms
              }

              handleTrackerQuestion({
                type: "FS",
                event: "paymentMethodSet",
                data: {eventData}
              });
              handleTrackerQuestion({
                type: "GA",
                data: {
                  event: "paymentMethodSet",
                  ...eventData
                },
              });
              handleTrackerQuestion({
                type: "FS",
                event: "policyPurchased",
                data: {
                  eventData
                }
              });
              handleTrackerQuestion({
                type: "GA",
                data: {
                  event: "policyPurchased",
                  ...eventData
                },
              });

              navigate("/waitingsignature");
            }
          });
        } else {
          eventDetails = [
            {
              leadId: leadId,
              paymentMethod: "SEPA",
              errorMessage: "SAVE_PAYMENT_METHOD" + res,
              token: token,
              brokerEmployeeId: brokerId,
            },
          ];
          trackingRepository.add({ eventType: "PAYMENT_ERROR", eventDetails });
        }
      });
    }
  };

  const IBAN_STYLE = {
    base: {
      color: "#104944",
      fontSize: "14px",
      "::placeholder": {
        color: "#104944",
      },
      ":-webkit-autofill": {
        color: "#104944",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
      ":-webkit-autofill": {
        color: "#fa755a",
      },
    },
  };

  const IBAN_ELEMENT_OPTIONS = {
    supportedCountries: ["SEPA"],
    placeholderCountry: process.env.REACT_APP_DOMAIN_LOCALE.toUpperCase(),
    style: IBAN_STYLE,
  };

  return (
    <div className="card-select-payment-type">
      <div className="subcontainer">
        <h5>{translate("payment.paymentMethod.sepa")}</h5>
        <div className="container-card">
          <IbanElement options={IBAN_ELEMENT_OPTIONS} />
        </div>
      </div>
      <div className="button-container-payment">
        <div className="container-legal-text">
          <CustomRadio
            answer={acceptConditions}
            toMap={[
              { value: "true", text: translate("payment.paymentMethod.sepa.acceptconditions") },
            ]}
            handleValue={() => handleAcceptConditions("true")}
            vertical={true}
          />
          <div>
            {!moreConditions && (
              <button
                className="more-info"
                onClick={() => setMoreConditions(true)}
              >
                {translate("payment.paymentMethod.sepa.showconditions")}
              </button>
            )}
            {moreConditions && (
              <button
                className="more-info"
                onClick={() => setMoreConditions(false)}
              >
                {translate("payment.paymentMethod.sepa.hideconditions")}
              </button>
            )}
            {moreConditions && (
              <>
                <br></br>
                <span dangerouslySetInnerHTML={{__html: translate("payment.paymentMethod.sepa.conditions")}} />
              </>
            )}
          </div>
        </div>
        <ButtonPrimary
          content={textButton}
          onClick={handleSubmit}
          disabled={acceptConditions !== "true" || loading ? true : false}
          loading={loading}
        />
      </div>
    </div>
  );
};

export default SepaPayment;
