import React, { useContext, useState } from "react";
import TagManager from "react-gtm-module";
import * as FullStory from '@fullstory/browser';
import { IntentType } from "../api/request/Lead/Model/Response/CarrierPricesResponse.model";
import HttpLeadRepository from "../api/request/Lead/lead.service";
import { ContextGetlife } from "./ContextGetlife";

export type CoverageType = "F" | "FIPA";

export type GenderTag = "M" | "F";

interface HandleTrackerI {
  type: "FS" | "GA";
  data: any;
  event?: string;
}

interface FsEventI {
  FsEvent: string;
  data?: {
    leadId?: string
  };
}

interface GoogleTagManager {
  event?: string;
  page?: string;
  user_id?: string;
  userType?:
    | "brokerAdmin"
    | "brokerEmployee"
    | "getlifeEmployee"
    | "leadStatus";
  brokerId?: string | number;
  brokerEmployeeId?: string | number;
  brokerName?: string;
  leadId?: string | number;
  calculatedPremium?: string | number;
  paymentMethod?:
    | "cardDirect"
    | "cardLinkpaymentSms"
    | "cardLinkpaymentEmail"
    | "sepa";
  birthDate?: string;
  gender?: GenderTag;
  calculatedCapital?: string | number;
  finalCapital?: string | number;
  intent?: IntentType;
  postalCode?: string | number;
  coverage?: CoverageType;
  answeredQ?: string;
  answers?: string;
  email?: string;
  phone?: string;
  utm_source?: string | null;
  utm_campaign?: string | null;
  utm_content?: string | null;
  utm_medium?: string | number | null;
  utm_term?: string | null;
  utm_test?: string | null;
  gclid?: string | null;
  fbclid?: string | null;
}

interface GoogleContextProps {
  handleTrackerQuestion: (value: HandleTrackerI) => void;
}

export const GoogleContext = React.createContext({} as GoogleContextProps);

export const GoogleProvider = ({ children }: any) => {

  const isNotDEV = process.env.REACT_APP_ENVIROMENT !== "DEV";

  if (isNotDEV) {
    const tagManagerArgs = {
      gtmId: "GTM-PL83KZL",
    };
    TagManager.initialize(tagManagerArgs);
    FullStory.init({ orgId: 'o-19FZSH-na1' });
  }

  const [dataLayer, setDataLayer] = useState<GoogleTagManager>(
    {} as GoogleTagManager
  );

  const { leadId } = useContext(ContextGetlife);

  const getUserID = (): string => {
    const indexGID: number = document.cookie.search("_gid");
    const gid: string[] | boolean = indexGID !== -1 ? document.cookie
      .substring(indexGID)
      .split(";")[0]
      .split(".") : false
    const userID: string = gid ? gid[gid.length - 2].concat(".", gid[gid.length - 1]) : "";

    return userID;
  };

  const leadRepository = new HttpLeadRepository(leadId);

  const handleChangeDataLayer = (data?: GoogleTagManager): void => {
    setDataLayer({ user_id: getUserID(), ...dataLayer, ...data });
    TagManager.dataLayer({ dataLayer: { user_id: getUserID(), ...data } });

    let dataEvent = {
      leadUlid: data?.leadId,
      eventType: data?.event,
      platform: "ga4",
      createdAt: new Date(),
      payload: data
    }
    if (data?.leadId) {
      leadRepository.saveEvent({data: dataEvent})
    }
  };

  const sendFullStoryEvent = ({ FsEvent, data = {} }: FsEventI) => {
    FullStory.event(FsEvent, data);
    let dataEvent = {
      leadUlid: data.leadId,
      eventType: FsEvent,
      platform: "fullstory",
      createdAt: new Date(),
      payload: data
    }
    if (data.leadId) {
      leadRepository.saveEvent({data: dataEvent})
    }
   
  };

  const handleTrackerQuestion = ({ type, event, data }: HandleTrackerI) => {
    const trackers = {
      FS: () => sendFullStoryEvent({ FsEvent: event!, data }),
      GA: () => handleChangeDataLayer(data),
    };

    return isNotDEV && trackers[type]();
  };


  const values = {
    handleTrackerQuestion,
  };

  return (
    <GoogleContext.Provider value={values}>{children}</GoogleContext.Provider>
  );
};
