// Controller
import PoliciesController from "./controllers/Policies.controller";
// Components
import { CustomTable } from "../components/CustomTable/custom-table.component";
// Styles
import "./Policies.scss";

const Policies = () => {
  const { tableProps } = PoliciesController();

  return (
    <div className="policy__container">
      <CustomTable {...tableProps} />
    </div>
  );
};

export default Policies;
