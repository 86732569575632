import { ROLES } from "../../../utils/roles";

// eslint-disable-next-line import/no-anonymous-default-export
export const errors = {
  email: "No tiene email",
  phone: "No tiene teléfono",
  statusInactive: "MEnsaje del modal",
};

export const market = [
  {
    type: "cell",
    header: "Mercado",
    ref: "market",
    elementType: "text",
  },
]

export const buttons = [
  {
    type: "button",
    text: "Recuperar",
    ref: "recuperar",
    elementType: "button",
  },
  {
    type: "buttonEdit",
    text: "Editar",
    ref: "editar",
    elementType: "button",
  },
];

export const columns_projects = [
  {
    type: "cell",
    header: "Email",
    ref: "email",
    class: "orangy",
    elementType: "text",
  },
  {
    type: "cell",
    header: "Nombre de referencia",
    ref: "referenceName",
    elementType: "text",
  },
  {
    type: "cell",
    header: "Estado",
    ref: "status",
    class: "greeny",
    elementType: "text",
  },
  {
    type: "cell",
    header: "Teléfono",
    ref: "phone",
    class: "orangy",
    elementType: "text",
  },
  {
    type: "cell",
    header: "Fecha creación",
    ref: "creationDate",
    sortable: true,
    elementType: "text",
  },
  {
    type: "cell",
    header: "Prima Anual",
    ref: "yearlyPrice",
    sortable: true,
    elementType: "text",
  },
];

export const columns_projects_admin = [
  ...columns_projects,
  {
    type: "cell",
    header: "Propietario",
    ref: "owner",
    elementType: "text",
  },
];

export const columns = { 
    employee: columns_projects,
    admin: columns_projects_admin,
    life5: [
      ...columns_projects_admin,
      ...market,
      {
        type: "cell",
        header: "Corredor",
        ref: "carrier",
        elementType: "text",
      },
    ]
  }


export const permission = [ROLES.SUPER_ADMIN, ROLES.ADMIN];
