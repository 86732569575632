import { useContext, useEffect, useState } from "react";
import "./calendarGetlife.scss";

import moment from "moment";
import { Dialog, DialogContent } from "@material-ui/core";
import SelectGetlife from "../select/SelectGetlife";
import { Calendar } from "get-life-storybook-ts";
import { I18nContext } from "../../../contexts/i18n.context";

export default function CalendarExtendedGetlife(props) {
  const [finalValue, setFinalValue] = useState(props.answer);
  const [selectedDay, setSelectedDay] = useState(null);
  const [calendarOpen, setCalendarOpen] = useState(false);
  const [selectedOption] = useState(props.answer);

  const {
    state: { translate },
  } = useContext(I18nContext);

  const handleCalendarClose = () => {
    setCalendarOpen(false);
  };

  useEffect(() => {
    if (selectedDay !== null) {
      setFinalValue(
        `${selectedDay.getFullYear()}-${
          selectedDay.getMonth() + 1
        }-${selectedDay.getDate()}`
      );
      handleCalendarClose();
    }
  }, [selectedDay]);

  useEffect(() => {
    if (finalValue !== null) props.handleValues(finalValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [finalValue]);

  const today = moment();
  const todayDate = new Date();
  const finalDate = new Date().setMonth(todayDate.getMonth() + 3);
  const tomorrow = moment().add(1, "days");
  const nextWeek = moment().add(7, "days");
  const fifteenDays = moment().add(15, "days");
  const oneMonth = moment().add(1, "months");

  const handleRadios = (value) => {
    if (value.value === "other") {
      setCalendarOpen(true);
    } else {
      setFinalValue(value.value);
    }
  };

  return (
    <div className={"calendar_extended_container"}>
      <SelectGetlife
        disabled={props.disabled}
        toMap={[
          { value: today.format("YYYY-MM-DD"), label: translate("question.calendar.today") },
          { value: tomorrow.format("YYYY-MM-DD"), label: translate("question.calendar.tomorrow") },
          { value: nextWeek.format("YYYY-MM-DD"), label: translate("question.calendar.oneWeek") },
          { value: fifteenDays.format("YYYY-MM-DD"), label:translate("question.calendar.twoWeek") },
          { value: oneMonth.format("YYYY-MM-DD"), label:  translate("question.calendar.month") },
          { value: "other", label: translate("question.calendar.otherDay") },
        ]}
        type={"select"}
        content={translate("question.calendar.placeholder")}
        handleValues={handleRadios}
        handleChange={handleRadios}
        placeholder={translate("question.calendar.placeholder")}
        answer={selectedOption}
      />

      <p>
        {translate("question.calendar.subtext")}
        {" "}
        <strong>
          {finalValue !== null &&
            finalValue !== "" &&
            finalValue !== false &&
            moment(finalValue).format("DD-MM-YYYY")}
        </strong>
      </p>
      <Dialog
        fullWidth={false}
        maxWidth={"lg"}
        open={calendarOpen}
        onClose={handleCalendarClose}
      >
        <DialogContent className="calendar_extended_container__modal">
          <Calendar
            date={selectedDay}
            setDate={setSelectedDay}
            maxDate={new Date(finalDate)}
            minDate={todayDate}
            locale={process.env.REACT_APP_DOMAIN_LOCALE}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
}
