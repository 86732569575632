import React from "react";
import Typography from "../../atoms/Typography/Typography.component";

import ClientOpinion from "../../molecules/ClientOpinion/ClientOpinion.component";
import "./OurClientsOpinion.styles.scss";

interface Opinions {
    name: string;
    text: string;
}

interface OurClientsOpinionProps {
    opinions: Array<Opinions>;
    variantTitle:
    | "h2"
    | "h3"
    | "h4"
    | "h5"
    | "h6";
    title: string;
}

const OurClientsOpinion: React.FC<OurClientsOpinionProps> = ({opinions, variantTitle, title}) => {   

    const clientsOpinions = opinions.map(opinion => {
        return (
                <ClientOpinion name={opinion.name} text={opinion.text} variantName="h4" variantText="paragraph"/>
            
        )
    })

    return (
        <section className="ourClientsOpinion">
            <Typography variant={variantTitle} text={title} className="ourClientsOpinion--title"/>
            <div className="ourClientsOpinion--container">
                {clientsOpinions}
            </div>
        </section>
    )
}

export default OurClientsOpinion;