import Cards from "../../../checkpoint/components/Cards/Cards";
import CouponModal from "../../organisms/Modal/CouponModal/CouponModal.component";
import ModalMoreInfo from "../../../checkpoint/components/ModalMoreInfo/ModalMoreInfo";
import Personalization from "../../../checkpoint/components/Personalization/Personalization";
import UpgradeCapitalModal from "../../../components/UpgradeCapitalModal/UpgradeCapitalModal";
import CheckPointController from "./CheckPoint.controller";
import "./CheckPoint.styles.scss";
import CouponConditions from "../../organisms/Modal/CouponConditions/CouponConditions.component";

const CheckPoint = (): JSX.Element => {
  const {
    boundaries,
    capital,
    carrierPrices,
    complete,
    contract,
    couponInfo,
    downloadPDF,
    downloadPDFCards,
    downloadPDFGuareantee,
    fetchPromo,
    handleCapital,
    handleMoreInfo,
    handleClickIcon,
    insuranceType,
    intention,
    isWholeYearAppliedPromo,
    leadId,
    pdfElement,
    premiumAllowed,
    promotion,
    setShowModal,
    setShowModalCoupon,
    setShowModalInfo,
    setUpGradedOpen,
    showModal,
    showModalCoupon,
    showModalInfo,
    translate,
    upgraded,
    upGradedOpen,
    validateCoupon,
    downloadableFiles
  } = CheckPointController();

  return (
    <>
      <CouponModal
        couponCode={""}
        fetchPromo={fetchPromo}
        showModal={showModalCoupon}
        yearlyPrice={carrierPrices[0]?.annualPrice}
        setShowModal={setShowModalCoupon}
        translate={translate}
        validateCoupon={validateCoupon}
      />

      {couponInfo && (
        <CouponConditions
          translate={translate}
          showModal={showModalInfo}
          setShowModal={setShowModalInfo}
          couponInfo={couponInfo}
        />
      )}
      <div className="productosDisponibles">
        <h1>{translate("checkpoint.title")}</h1>
        <div className="cards">
          {Object.keys(carrierPrices).map(function (key: any, index: number) {
            return (
              <div className="card">
                <Cards
                  card={carrierPrices[key]}
                  complete={complete}
                  contract={contract}
                  downloadPDF={downloadPDFCards}
                  handleMoreInfo={handleMoreInfo}
                  index={index}
                  insuranceType={insuranceType}
                  intention={intention}
                  key={key}
                  leadId={leadId}
                  pdfElement={pdfElement}
                  promotion={promotion}
                  setShowModalInfo={setShowModalInfo}
                  translate={translate}
                  downloadableFiles={downloadableFiles}
                  isWholeYearAppliedPromo={isWholeYearAppliedPromo}
                />
              </div>
            );
          })}
        </div>
        <Personalization
          boundaries={boundaries}
          capital={capital}
          downloadPDF={downloadPDFGuareantee}
          handleCapital={handleCapital}
          handleClickIcon={handleClickIcon}
          insuranceType={insuranceType}
          leadId={leadId}
          premiumAllowed={premiumAllowed}
          promotion={promotion}
          translate={translate}
          validateCoupon={validateCoupon}
        />
      </div>

      <ModalMoreInfo
        capital={capital}
        downloadPDF={downloadPDF}
        insuranceType={insuranceType}
        leadId={leadId}
        pdfElement={pdfElement}
        setShowModal={setShowModal}
        showModal={showModal}
        translate={translate}
      />

      <UpgradeCapitalModal
        upGradedOpen={upGradedOpen}
        handleUpgradedClose={() => setUpGradedOpen(false)}
        capitalUpgrade={upgraded.capital}
      />
    </>
  );
};

export default CheckPoint;
