import React from "react";
import { IconProps } from "./IconProps.interface";

interface StarIconProps extends IconProps {
  colorRect?: string;
}

const StarIcon: React.FC<StarIconProps> = ({
  width = "32",
  height = "32",
  color = "white",
  colorRect = "#148F63",
  className,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 32"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.828125"
        y="0.908203"
        width="30.5499"
        height="30.5499"
        rx="15.275"
        fill={colorRect}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.1039 20.5742L20.3754 19.5008L22.1601 24.9549L16.1039 20.5742ZM25.9343 13.5246H18.4152L16.1039 6.50391L13.7925 13.5246H6.27344L12.3589 17.8762L10.0476 24.8968L16.1331 20.5452L19.878 17.8762L25.9343 13.5246Z"
        fill={color}
      />
    </svg>
  );
};

export default StarIcon;
