export default function apiPaymentMethod(
	token,
	paymentMethod,
) {
	const bearer = 'Bearer ' + token;
	return fetch(
		process.env.REACT_APP_CUSTOMER_APP_URL + `api/lead/payment/method/${paymentMethod['leadId']}`,

		{
			method: 'POST',
			headers: {
				Authorization: bearer,
			},
			body: JSON.stringify({
				"paymentMethod": paymentMethod['payment_method'],
                "type": paymentMethod['type'],
                "setupIntent": paymentMethod['setupIntent']
			}),
		}
	).then((response) => {
		if (response.status === 200) {
			return response.json();
		} else {
			return 'error';
		}
	});
}
