import React from "react";
import { intentionCheckOut } from "../../../utils/staticData";
import numberFormat from "../../../utils/numberFormat";
import ButtonSecondary from "../../components/buttons/ButtonSecondary";
import ModalSendEmail from "../../components/ModalSendEmail/ModalSendEmail";
import CheckIcon from "@mui/icons-material/Check";

import "../css/_productCard.scss";
import CrossedOut from "../../Rebranding/atoms/CrossedOut/CrossedOut.component";
import InfoBox from "../../Rebranding/molecules/InfoBox/InfoBox.component";
import TicketIcon from "../../Rebranding/atoms/Icons/TicketIcon";

const SelectedPrice = ({ billingSelect, election, productCard, translate }) => (
  <p className="productCard--main__price">
    <span
      className={` ${
        billingSelect === "monthly" &&
        (election ? "periodSelect" : "periodSelectElection")
      }`}
    >
      {numberFormat(
        productCard.promotion
          ? productCard.promotion.price
          : productCard.monthlyPrice,
        "price"
      )}
      {translate("card.period.monthly")}
    </span>{" "}
    {translate("card.period.or")}{" "}
    <span
      className={` ${
        billingSelect === "yearly" &&
        (election ? "periodSelect" : "periodSelectElection")
      }`}
    >
      {numberFormat(
        productCard.promotion
          ? productCard.promotion.price * 12
          : productCard.annualPrice,
        "price"
      )}
      {translate("card.period.yearly")}
    </span>
  </p>
);

const ProductCard = (props) => {
  const {
    billingSelect,
    downloadPDF,
    election,
    emailToSend,
    handleModalSend,
    productCard,
    sendEmail,
    setShowModalCoupon,
    setShowModalSend,
    showModalSend,
    translate
  } = props;

  return (
    <>
      <article className="productCard">
        <section className="productCard--header">
          <div>
            <h3 className="productCard--header__title">{translate("checkpoint.cardheader.title")}</h3>
            <p className="productCard--header__subtitle">
            Life5 {intentionCheckOut[productCard.intention]}
            </p>
          </div>
          <p className="productCard--header__company">
            {productCard.actualCarrier}
          </p>
        </section>
        <section className="productCard--main">
          {productCard.promotion && (
            <CrossedOut
              className="productCard--main__delete"
              data={
                <p>
                  {numberFormat(productCard.monthlyPrice, "price")} {translate("card.period.monthly")} {translate("card.period.or")}{" "}
                  {numberFormat(productCard.annualPrice, "price")} {translate("card.period.yearly")}
                </p>
              }
            />
          )}
          <SelectedPrice
            billingSelect={billingSelect}
            election={election}
            productCard={productCard}
            translate={translate}
          />

          <div className="productCard--main__div">
            <CheckIcon />
            <p className="productCard--main__details">
              {translate("payment.card.death")} {numberFormat(productCard.capital, "capital")}
            </p>
          </div>
          {productCard.insuranceType === "premium" ? (
            <div className="productCard--main__div">
              <CheckIcon />
              <p className="productCard--main__details">
                {translate("payment.card.ipa")}
                {numberFormat(productCard.capital, "capital")}
              </p>
            </div>
          ) : (
            ""
          )}
        </section>
        <section className="productCard--footer">
          <div className="productCard--footer__button">
            <ButtonSecondary
              content={translate("payment.card.button.conditions.download")}
              type="download"
              handleChange={downloadPDF}
            />
          </div>
          <div className="productCard--footer__button">
            <ButtonSecondary
              content={translate("payment.card.button.conditions.send")}
              type="sendConditions"
              handleChange={handleModalSend}
            />
          </div>
        </section>
        {productCard.promotion && (
          <InfoBox
            className="productCard__infoBox"
            icon={<TicketIcon />}
            infoIcon={!!productCard.promotion.promotionalCode}
            onClick={() => setShowModalCoupon(true)}
            text={productCard.promotion.description}
          />
        )}
      </article>
      <ModalSendEmail
        showModal={showModalSend}
        setShowModal={setShowModalSend}
        emailToSend={emailToSend}
        sendEmail={(email, text) => sendEmail(email, text)}
      />
    </>
  );
};

export default ProductCard;
