import * as React from "react";
import FAQuestion from "../../molecules/FAQuestion/FAQuestion.component";
import Typography from "../../atoms/Typography/Typography.component";

interface Question {
  color?: string;
  title: string;
  text: string;
}
interface FAQuestionsProps {
  arrayFAQuestions: Array<Question>;
  title: string;
  variantTitle?: "h1" | "bold";
}

const FAQuestions: React.FC<FAQuestionsProps> = ({
  title,
  arrayFAQuestions,
  variantTitle = "h1",
}): JSX.Element => {
  return (
    <>
      <div className="questionSection">
        <Typography variant={variantTitle} text={title} />
        <div className="questionBlock">
          {arrayFAQuestions.map((question: Question) => (
            <>
              <FAQuestion
                title={question.title}
                text={question.text}
                color={question.color}
              />
            </>
          ))}
        </div>
      </div>
    </>
  );
};

export default FAQuestions;
