import { useEffect, useState } from "react";
import "./calendarGetlife.scss";
import { Calendar } from "get-life-storybook-ts";
import { DateTime } from "luxon";

const formatDate = (date) => {
  const separatedDate = date.split("-");
  const newDate = separatedDate.map((item) => (item < 10 ? `0${item}` : item));
  return newDate.join("-");
};

const CalendarGetlife = ({ answer, handleValues }) => {
  const today = DateTime.now();
  const finalDate = today.plus({ month: 3 });
  const [selectedDay, setSelectedDay] = useState(
    answer ? DateTime.fromSQL(formatDate(answer)) : today
  );

  useEffect(() => {
    if (selectedDay !== today) {
      const year = selectedDay.year;
      const month = selectedDay.month;
      const day = selectedDay.day;
      handleValues(`${year}-${month}-${day}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDay]);

  const handleChangeDate = (newDate) => {
    const transformDate = DateTime.fromJSDate(newDate);
    setSelectedDay(transformDate);
  };

  return (
    <>
      <Calendar
        date={selectedDay.toJSDate()}
        setDate={handleChangeDate}
        maxDate={finalDate.toJSDate()}
        locale={process.env.REACT_APP_DOMAIN_LOCALE}
      />
    </>
  );
};
export default CalendarGetlife;
