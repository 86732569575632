import * as React from "react";
import { ContextGetlife } from "../../../../contexts/ContextGetlife";
import { I18nContext } from "../../../../contexts/i18n.context";
import useForm from "../../../../hooks/useForm.hook";

export interface PostStackQuestionControllerProps {
  initialValues: any;
  fetch: any;
  requiredFields: any;
}

const PostStackQuestionController = ({
  initialValues,
  fetch,
  requiredFields,
}: PostStackQuestionControllerProps) => {
  const {
    state: { translate },
  } = React.useContext(I18nContext);
  const { setLoading, broker, brokerId } = React.useContext(ContextGetlife);
  console.log(brokerId)
  const ENVIROMENT = process.env.REACT_APP_ENVIROMENT;

  // const BROKER_FR = ((ENVIROMENT === "LOCAL" || ENVIROMENT === "DEV" || ENVIROMENT === "TEST") && (broker.brokerageId === 459)) || (ENVIROMENT === "PRD" && broker.brokerageId === 459);
  // const isGetlifeBroker = broker.brokerageId === 1 || BROKER_FR;
  const { values, setValue, error, handleSubmit, setValues } = useForm({
    initialValues: initialValues,
    setLoading,
    fetch,
    requiredFields,
  });

  return {
    broker,
    error,
    handleSubmit,
    setValue,
    setValues,
    translate,
    values,
  };
};

export default PostStackQuestionController;
