import { useState } from "react";
import ArrowIcon from "../../Rebranding/atoms/Icons/Arrow.component";
import Typography from "../../Rebranding/atoms/Typography/Typography.component";
import "./tipContainer.scss";

export default function TipContainer(props) {
  const { header, content } = props;
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className="tipContainer-mainContainer">
      <div
        className="tipContainer-container"
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className="tipContainer-headerContainer">
          <ArrowIcon className={`${isOpen && "arrowRotate"}`} />

          <Typography variant="bold" text={header} />
        </div>
        {isOpen && (
          <div className="tipContainer-contentMainContainer">
            <div className="tipContainer-contentContainer">
              <Typography variant="paragraph" text={content} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
