import { DateTime } from "luxon";
import REGEX from "./regExp";
import apiLeadValidateZipCodeFr from "../api/request/apiLeadValidateZipCodeFr";

const DOMAIN = process.env.REACT_APP_DOMAIN_LOCALE;

export const validateRegex = (regex, field) => {
  if (regex === "PHONE" && DOMAIN === "fr") {
    regex = "PHONEFR";
  }
  const correctField = field.match(REGEX[regex]);
  return !correctField;
};

const validateMaxMin = (field, max, min, name) => {
  let error;
  if (field > max) {
    error = {
      message: `Este campo supera el importe máximo ${max}`,
      field: name,
    };
  } else if (field < min) {
    error = {
      message: `Este campo es inferior al mínimo disponible ${min}`,
      field: name,
    };
  }
  return error;
};

const validCountries =  (DOMAIN === "es" || DOMAIN === "pt" || DOMAIN === "it") ? ["ES"] : ["FR"];

const validateCitizen = (field) => {
  if (typeof field === "string") {
    return validCountries.includes(field);
  }
  return validCountries.includes(field.value);
};

const actualYear = new Date().getFullYear();
const maximumYear = actualYear - 18;
const minumYear = actualYear - 75;

const validateDate = (field) => {
  return parseInt(field) > maximumYear || parseInt(field) < minumYear;
};

const isInvalidField = ({
  type,
  field,
  name,
  optional,
  max,
  min,
  translate,
}) => {
  
  let error;

  if (!field && !optional) {
    error = { message: translate("validator.error.request") , field: name };
  } else if (field && type === "number") {
    error = validateMaxMin(field, max, min, name);
  } else if (field && type === "zipCode") {
    if ((DOMAIN === "es" || DOMAIN === "pt" || DOMAIN === "it") && validateRegex("ZIPCODE", field)) {
      error = {
        message: translate("validator.error.zipCode"),
        field: name,
      };
    }

    if (DOMAIN === "fr") {
      apiLeadValidateZipCodeFr(field).then((res) => {
      if (res.isValidPostalCodeFR === false) {
        error = {
          message: translate("validator.error.zipCode"),
          field: name,
        };
      }
      })
      
    }
    
  } else if (field && type === "phone" && validateRegex("PHONE", field)) {
    error = {
      message: translate("validator.error.phone"),
      field: name,
    };
  } else if (field && type === "email" && validateRegex("EMAIL", field)) {
    error = {
      message: translate("validator.error.email"),
      field: name,
    };
  } else if (field && type === "citizenship" && !validateCitizen(field)) {
    error = {
      message: translate("validator.error.citizenship"),
      field: name,
    };
  } else if (field && type === "date" && validateDate(field)) {
    error = {
      message: translate(`errors.validator.${name}`)
        .replace("$", minumYear)
        .replace("#", maximumYear),
      field: name,
    };
  }
  return error;
};

const isInvalidFieldDashboard = ({ values, name, translate }) => {
  if (!values.email && !values.referenceName) {
    return {
      message: translate("calculator.error.requestField"),
      field: name,
    };
  }
};

const validateForm = ({ values, requiredFields, dashBoard, translate }) => {
  const invalidFields = requiredFields.map((field) => {
    const { name, type, optional, max, min } = field;
    const fieldValue = values[name] || "";
    return isInvalidField({
      type,
      field: fieldValue,
      name,
      optional,
      max,
      min,
      translate,
    });
  });

  const invalidFielsDasboard = dashBoard
    ? [
        ...invalidFields,
        isInvalidFieldDashboard({ values, name: "email", translate }),
        isInvalidFieldDashboard({ values, name: "referenceName", translate }),
      ]
    : invalidFields;
  const validate = invalidFielsDasboard.every((field) => !field);

  if (!validate) {
    const error = invalidFielsDasboard.map((e) => {
      if (e) {
        const { field, message } = e;
        return {
          field: field,
          message: message,
        };
      }
      return {
        field: "",
        message: "",
      };
    });
    throw new Error(JSON.stringify(error));
  }
};

export default validateForm;


export const dateInPast = ({
  value,
  message,
}) => {
  const valueDate = DateTime.fromISO(value);
  const birthdayCuttoff = DateTime.now();
  if (valueDate >= birthdayCuttoff) {
    return {
      error: true,
      message: message || "",
    };
  } else {
    return {
      error: false,
      message: "",
    };
  }
};

export const dateInFuture = ({
  value,
  message,
}) => {
  const valueDate = DateTime.fromISO(value);
  const birthdayCuttoff = DateTime.now();
  if (valueDate <= birthdayCuttoff) {
    return {
      error: true,
      message: message || "",
    };
  } else {
    return {
      error: false,
      message: "",
    };
  }
};