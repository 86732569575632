// Components
import UploadFile from "../../../components/UploadFile/UploadFile.component";
// Styles
import "./CompanyInformation.view.scss";
// Constants
import CompanyInformationController from "./CompanyInformation.controller";
import InputGeneral from "../../molecules/InputGeneral/InputGeneral.component";
import { errorMessage } from "../../../../utils/error";
import Button from "../../atoms/Buttons/ButtonBase/Button.component";

const CompanyInformation: React.FC = () => {
  const { error, handleSubmit, setValue, values, translate } =
    CompanyInformationController();
  return (
    <div className="company-information__container">
      <div className="company-information__title">
        <h1>{translate("companyInfo.header.title")}</h1>
        <p>{translate("companyInfo.header.subtitle")}</p>
      </div>

      <div className="company-information__form">
        <div className="company-information__form__title">
          <h3>{translate("companyInfo.section.firsTitle")}</h3>
        </div>
        <div className="company-information__item">
          <div className="company-information__item-input">
            <InputGeneral
              editValue={values["name"]}
              placeholder="E.g. Life5"
              label={translate("companyInfo.form.name")}
              setter={(newValue) => setValue("name", newValue)}
              name="name"
              error={errorMessage(error, "name")}
            />
          </div>
        </div>
        <div className="company-information__item">
          <div className="company-information__item-input">
            <InputGeneral
              editValue={values["address"]}
              placeholder="E.g. 140 Paseo de la Castellana, 28046, Madrid"
              label={translate("companyInfo.form.address")}
              setter={(newValue) => setValue("address", newValue)}
              name="address"
              error={errorMessage(error, "address")}
            />
          </div>
        </div>
        <div className="company-information__item">
          <div className="company-information__item-input">
            <UploadFile
              label={translate("companyInfo.form.logo")}
              name="logo"
              value={values["logo"]}
              setFile={setValue}
              title={translate("companyInfo.form.title.logo")}
            />
          </div>
        </div>
        <div className="company-information__item">
          <div className="company-information__item-input">
            <InputGeneral
              editValue={values["idCard"]}
              placeholder="E.g. 2348745"
              label={translate("companyInfo.form.idCard")}
              setter={(newValue) => setValue("idCard", newValue)}
              name="idCard"
              error={errorMessage(error, "idCard")}
            />
          </div>
        </div>
        <div className="company-information__item">
          <div className="company-information__item-input">
            <InputGeneral
              editValue={values["idDgsfp"]}
              label={translate("companyInfo.form.idDgsfp")}
              setter={(newValue) => setValue("idDgsfp", newValue)}
              name="idDgsfp"
              placeholder={"E.g. 2348745"}
              error={errorMessage(error, "idDgsfp")}
            />
          </div>
        </div>
        <div className="company-information__form__title">
          <h3>{translate("companyInfo.section.secondTitle")}</h3>
        </div>
        <div className="company-information__item">
          <div className="company-information__item-input">
            <InputGeneral
              editValue={values["contactEmail"]}
              label={translate("companyInfo.form.contactEmail")}
              placeholder={"E.g. francisco@life5.com"}
              setter={(newValue) => setValue("contactEmail", newValue)}
              name="contactEmail"
              error={errorMessage(error, "contactEmail")}
            />
          </div>
        </div>
        <div className="company-information__item">
          <div className="company-information__item-input">
            <InputGeneral
              editValue={values["contactPhone"]}
              label={translate("companyInfo.form.contactPhone")}
              setter={(newValue) => setValue("contactPhone", newValue)}
              name="contactPhone"
              placeholder={"E.g. 655 21 48 09"}
              error={errorMessage(error, "contactPhone")}
            />
          </div>
        </div>
        <div className="company-information__item">
          <div className="company-information__item-submit">
            <Button
              onClick={() => {
                handleSubmit();
              }}
            >
              {translate("companyInfo.button.save")}{" "}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyInformation;
