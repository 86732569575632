import { I18nContext } from "./../../../contexts/i18n.context";
/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useContext, useRef } from "react";
import moment from "moment";
import HttpLeadRepository from "../../../api/request/Lead/lead.service";
import { InsuranceType } from "../../../api/request/Lead/Model/Request/CarrierPricesRequest.model";
import {
  AvailableTraits,
  CapitalLimits,
} from "../../../api/request/Tarifier/Model/Response/CapitalLimitsResponse.model";
import { PDF } from "../../../api/request/Lead/Model/Response/CarrierPricesResponse.model";
import BrokerResponse from "../../../api/request/Broker/Model/Response/BrokerResponse.model";

interface EditProjectsControllerProps {
  broker: BrokerResponse;
  data: any;
  modalData: any;
  setData: (data: any) => void;
  setShowModal: (showModal: boolean) => void;
  token: string;
}

export const sanitizeCapital = (value: string) => {
  return value.replace("€", "").replaceAll(".", "").trim();
};

interface EditProjectsFetchPayloadProps {
  birthdate: string;
  capital: number;
  email: string;
  insuranceType: "basic" | "premium";
  lastName: string;
  lastName2: string;
  name: string;
  phone: string;
  leadId: string;
  relatedEmail: string;
}

const EditProjectsController = ({
  broker,
  data,
  modalData,
  setData,
  setShowModal,
  token,
}: EditProjectsControllerProps) => {

  const [capital, setCapital] = useState<number>(0);
  const [chosenIPA, setChosenIPA] = useState<InsuranceType>();
  const [limits, setLimits] = useState<CapitalLimits>({
    maxCapital: 500000,
    minCapital: 50000,
    step: 25000,
  });
  const [prices, setPrices] = useState<any>({}); // FIXME
  const [traits, setTraits] = useState<AvailableTraits>({
    DEATH: true,
    DISABILITY: true,
  });
  const [isActiveRelatedEmail, setIsActiveRelatedEmail] = useState<boolean>(false);
  const [maxCapital, setMaxCapital] = useState<boolean>(false);
  const {
    state: { translate },
  } = useContext(I18nContext);

  const DOMAIN = process.env.REACT_APP_DOMAIN_LOCALE;

  const ENVIROMENT = process.env.REACT_APP_ENVIROMENT;

  const BROKER_FR = ((ENVIROMENT === "LOCAL" || ENVIROMENT === "DEV" || ENVIROMENT === "TEST") && broker.brokerageId === 459) || (ENVIROMENT === "PRD" && broker.brokerageId === 459);
  const isGetlifeBroker = broker.brokerageId === 1 || BROKER_FR;

  const pdfElementDownload = useRef<any>();

  const leadRepository = new HttpLeadRepository(token);

  //--------------------------------------------------------------------------------------//
  //                                      FORMATTERS                                      //
  //--------------------------------------------------------------------------------------//

  const formatBirthDate = (birthdate: string) => {
    let date = "";
    if (!!birthdate?.length) {
      date = moment(birthdate).local().format("DD-MM-YYYY");
    }
    return date;
  };

  const fetchUpdate = async (values: EditProjectsFetchPayloadProps) => {
    const {
      email,
      lastName,
      insuranceType,
      lastName2,
      name,
      capital: capitalData,
      phone,
      relatedEmail
    } = values;
    try {
      await leadRepository.postStackData(values.leadId, {
        capital: capital !== 0 ? capital : capitalData,
        email: email,
        insuranceType: insuranceType,
        lastName2: lastName2,
        lastName: lastName,
        name: name,
        phone: phone,
        relatedEmail: relatedEmail
      });
      const index = data.findIndex(
        (project: any) => project.leadId === values.leadId
      );
      data.splice(index, 1, { ...values, values });
      setData([...data]);
      setShowModal(false);
    } catch (error) {
      throw error;
    }
  };

  const fetchUpdatePrices = async () => {
    try {
      const response2 = await leadRepository.leadAskPrice({
        capital,
        insuranceType: chosenIPA,
        leadId: modalData.leadId,
      });
      setPrices(response2);
      setMaxCapital(response2.maxCapital)
    } catch (error) {
      throw error;
    }
  };

  const fetchLimits = async () => {
    try {
      const response3 = await leadRepository.getLeadExtendedBoundaries(
        modalData.leadId
      );
      const { minCapital, maxCapital, availableTraits } = response3;
      setLimits({
        maxCapital,
        minCapital,
        step: maxCapital >= 500000 ? 25000 : 10000,
      });
      setTraits(availableTraits);
    } catch (error) {}
  };

  const updatePrice = async () => {
    try {
      await leadRepository.recoverProject({
        leadId: modalData.leadId,
        data: { ...modalData, capital, insuranceType: chosenIPA },
      });
    } catch (error) {}
  };

  const handleCapital = (value: string) => {
    setCapital(parseInt(value));
  };

  const handleIPA = (value: "basic" | "premium" | undefined) => {
    setChosenIPA(value);
  };

  const addCapital = (setValue: (key: string, value: number) => void) => {
    if (capital + limits.step <= limits.maxCapital) {
      setCapital(capital + limits.step);
      setValue("capital", capital + limits.step);
    } else if (capital + limits.step > limits.maxCapital) {
      setCapital(limits.maxCapital);
      setValue("capital", limits.maxCapital);
    }
  };
  const removeCapital = (setValue: (key: string, value: number) => void) => {
    if (capital - limits.step >= limits.minCapital) {
      setCapital(capital - limits.step);
      setValue("capital", capital - limits.step);
    } else if (capital - limits.step < limits.minCapital) {
      setCapital(limits.minCapital);
      setValue("capital", limits.minCapital);
    }
  };

  useEffect(() => {
    (async () => {
      if (!!Object.keys(modalData).length) {
        setCapital(modalData?.capital);
        setChosenIPA(modalData?.insuranceType);
        await fetchLimits();
      }
    })();
  }, [modalData]);

  useEffect(() => {
    (async () => {
      if (capital && chosenIPA) {
        await updatePrice();
        await fetchUpdatePrices();
      }
    })();
  }, [capital, chosenIPA]);

  //--------------------------------------------------------------------------------------//
  //                                       CONFIGS                                        //
  //--------------------------------------------------------------------------------------//

  const IPAConfig = {
    items: [
      { label: translate("edit.project.radio.basic.label"), value: "basic" },
      {
        label: translate("edit.project.radio.premium.label"),
        value: "premium",
      },
    ],
  };

  const fetchPDF = async (templates: any, values: any) => {

    const {insuranceType, leadId} = values
    try {
      await leadRepository.recoverProject({
        leadId : leadId,
        data: {
          capital: capital,
          insuranceType: insuranceType,
        },
      });
      const response: PDF = await leadRepository.downloadPDF(leadId, templates);
      if (response.pdfUrl) {
        pdfElementDownload.current?.setAttribute("href", response.pdfUrl);
        pdfElementDownload.current?.click();
      }
    } catch (error) { }
  };

  const downloadPdf = async (values: any) => {

    const {insuranceType} = values;
    let templates = [];
    if ((DOMAIN === "es" || DOMAIN === "pt" || DOMAIN === "it")) {
      templates = ["general-exclusions", "death-any-cause-exclusions"];
      if (insuranceType === "premium") {
        templates = [
          "general-exclusions",
          "death-any-cause-exclusions",
          "ipa-any-cause-exclusions",
        ];
      }
    } else {
      templates = ["devis"];
    }

    await fetchPDF(templates, values);
  }

  const downloadPdfOffer = async (values: any) => {

    let templates = ["offer-w-base"];

    await fetchPDF(templates, values);
  }

  return {
    IPAConfig,
    addCapital,
    capital,
    chosenIPA,
    downloadPdf,
    downloadPdfOffer,
    isActiveRelatedEmail,
    isGetlifeBroker,
    fetchUpdate,
    formatBirthDate,
    handleCapital,
    handleIPA,
    limits,
    maxCapital,
    pdfElementDownload,
    prices,
    removeCapital,
    sanitizeCapital,
    setChosenIPA,
    setIsActiveRelatedEmail,
    traits,
    translate,
  };
};

export default EditProjectsController;
