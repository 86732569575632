import { Answers } from "../../../../api/request/Lead/Model/Response/NomenclatorResponse.model";
import Button from "../../atoms/Buttons/ButtonBase/Button.component";
import Typography from "../../atoms/Typography/Typography.component";
import {
  ActiveForms,
  Beneficiaries,
  QuestionTypes,
} from "../../pages/PostStack/PostStack.controller";
import FormAddress from "../Forms/FormAddress.component";
import FormBeneficiaries from "../Forms/FormBeneficiaries/FormBeneficiaries.component";
import FormNationality from "../Forms/FormNationality/FormNationality.component";
import FormPersonalData from "../Forms/FormPersonalData.component";
import PostStackQuestionController, {
  PostStackQuestionControllerProps,
} from "./PostStackQuestion.controller";
import "./PostStackQuestion.styles.scss";

export interface PostStackQuestionProps
  extends PostStackQuestionControllerProps {
  emailDisabled?: boolean;
  data?: Beneficiaries[] | Answers[];
  isAnswered: ActiveForms;
  setIsAnswered: (value: QuestionTypes) => void;
  title: string;
  type: QuestionTypes;
  variantTitle?: "paragraph" | "bold";
}

const PostStackQuestion = ({
  emailDisabled,
  data,
  fetch,
  initialValues,
  isAnswered,
  requiredFields,
  setIsAnswered,
  title,
  type,
  variantTitle = "paragraph",
}: PostStackQuestionProps) => {
  const { broker, error, handleSubmit, setValue, translate, setValues, values } =
    PostStackQuestionController({ initialValues, fetch, requiredFields });

  const Forms: any = {
    personalData: (
      <FormPersonalData
        broker={broker || false}
        emailDisabled={emailDisabled}
        translate={translate}
        values={values}
        setValue={setValue}
        error={error}
        disabled={isAnswered[type]}
      />
    ),
    nationality: (
      <FormNationality
        translate={translate}
        countries={(data as Answers[]) || []}
        values={values}
        setValue={setValue}
        error={error}
        addNationality
        disabled={isAnswered[type]}
      />
    ),
    address: (
      <FormAddress
        translate={translate}
        provinceList={(data as Answers[]) || []}
        values={values}
        setValues={setValues}
        setValue={setValue}
        error={error}
        disabled={isAnswered[type]}
      />
    ),
    beneficiaries: (
      <FormBeneficiaries
        translate={translate}
        beneficiaries={(data as Beneficiaries[]) || []}
        values={values}
        setValue={setValue}
        error={error}
        disabled={isAnswered[type]}
      />
    ),
  };

  return (
    <div className={"postStackForm-mainContainer"}>
      <div className="postStackForm-headerForm">
        <div className="postStackForm-titleHeader">
          <Typography variant={variantTitle} text={title} />
        </div>
        <div className="postStackForm-addButtonContainer">
          {!isAnswered[type] && (
            <Button
              onClick={() => handleSubmit()}
              className="postStackForm-button"
            >
              {translate("global.advance")}
            </Button>
          )}
        </div>
        {isAnswered[type] && (
          <Button
            onClick={() => setIsAnswered(type)}
            buttonType="secondary"
            className="postStackForm-button"
          >
            {translate("global.edit")}
          </Button>
        )}
      </div>
      <div className={`${type !== "beneficiaries" ? "postStackForm-form" : "postStackForm-formBeneficiaries"}`}>{Forms[type]}</div>
    </div>
  );
};

export default PostStackQuestion;
