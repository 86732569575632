import { get } from "../../core/HTTPService.service";
import ListRequest from "./Model/Request/ListRequest.model";
import InvoiceListResponse from "./Model/Response/InvoiceListResponse.model";
import ListResponse from "./Model/Response/ListResponse.model";

export default class HttpListRepository {
  private readonly API_URL_INVOICES:
    | string
    | undefined = `${process.env.REACT_APP_CUSTOMER_APP_URL}api/invoices`;

  private readonly API_URL_INVOICES_LIST:
    | string
    | undefined = `${this.API_URL_INVOICES}/list/`;

  private readonly API_URL_LIST_V2:
    | string
    | undefined = `${process.env.REACT_APP_CUSTOMER_APP_URL}api/v2/lists`;

  private readonly header: { Authorization: string } | undefined;

  constructor(token?: string) {
    this.header = token ? { Authorization: `Bearer ${token}` } : undefined;
  }

  private getUrlParams = (params: ListRequest): URL => {
    const apiUrl: URL = new URL(
      `${this.API_URL_LIST_V2}/${params.type}/${params.startDate}/${params.endDate}/${params.indexPage}/${params.pageSize}`
    );

    const queryParams: any = {};
    if (params.omniValue) {
      queryParams.omnivalue = params.omniValue;
    }
    if (params.ordering) {
      queryParams.orderfield = params.ordering.field;
      queryParams.order = params.ordering.direction;
    }
    apiUrl.search = new URLSearchParams(queryParams).toString();

    return apiUrl;
  };

  getListByType = async (data: ListRequest): Promise<ListResponse> => {
    let listData: ListResponse | null = null;
    const url: any = this.getUrlParams(data);
    try {
      const response: ListResponse = await get(url, this.header);
      listData = response;
    } catch (e) {
      throw e;
    }

    return listData;
  };

  getInvoiceList = async (): Promise<InvoiceListResponse> => {
    let data: InvoiceListResponse | null = null;
    try {
      const response: InvoiceListResponse = await get(
        `${this.API_URL_INVOICES_LIST}`,
        this.header
      );
      data = response;
    } catch (error) {
      throw error;
    }
    return data;
  };

  getDocumentInvoicesDetail = async (docId: string) => {
    let result;
    try {
      const response = await get(
        `${this.API_URL_INVOICES}/detail/${docId}`,
        this.header
      );
      result = response;
    } catch (error) {
      throw error;
    }
    return result;
  };
}
