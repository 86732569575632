import ContactSection from "../../molecules/ContactSection/ContactSection.component";
import GetContact from "../../molecules/GetContact/GetContact.component";
import InformationSecure from "../../molecules/InformationSecure/InformationSecure.component";
import Calculator from "../../organisms/Calculator/Calculator.component";
import FAQuestions from "../../organisms/FAQuestions/FAQuestions.component";
import OurClientsOpinion from "../../organisms/ourClientsOpinion/OurClientsOpinion.component";
import LandingController from "./Landing.controller";
import "./Landing.styles.scss";

const Landing = () => {
  const { broker, brokerId, contacts, customCode, FAQS, list, opinions, translate } =
    LandingController();

  return (
    <div className="landing">
      {customCode && <div dangerouslySetInnerHTML={{ __html: customCode }} />}
      <header className={"landing-header-container"}>
        <div className="landing-header-content">
          <div className="landing-header-logosContainer">
            <div className="landing-header-logosContainer-broker">
              {broker && broker.logoFilename ? (
                <>
                  <img
                    src={broker.logoFilename}
                    alt="logo del broker"
                    className="landing-header-brokerLogo"
                  />
                </>
              ) : broker ? (
                broker.brokerageName
              ) : (
                ""
              )}
            </div>
            <div className="landing-header-logosContainer-getlife">
              <img
                src="https://storage.googleapis.com/getlife-bucket-1/public/image/life5/Life5_Logo_White.svg"
                alt="GetLife"
                className="landing-header-getLifeLogo"
              />
            </div>
          </div>
        </div>
      </header>
      <main>
        <Calculator brokerId={brokerId} />

        <div className="landing-first-section" id="get-contact">
          <InformationSecure
            className="landing-information"
            variantTitle="h2"
            title={translate("landing.information.title")}
            subtitle={translate("landing.information.subtitle")}
            list={list}
          />

          <GetContact
            arrayBubbles={contacts}
            title={translate("landing.contactBubble.title")}
          />
        </div>
        <OurClientsOpinion
          opinions={opinions}
          variantTitle="h2"
          title={translate("landing.ourClientsOpinion.title")}
        />
        <FAQuestions
          arrayFAQuestions={FAQS}
          title={translate("landing.FAQuestions.title")}
        />

        <ContactSection
          variantTitle="h2"
          title={translate("landing.contactSection.title")}
          variantText="paragraph"
          text={translate("landing.contactSection.text")}
          link="#get-contact"
          labelContact={translate("landing.contactSection.button")}
        />
      </main>
      <div className="footer-bottom">
        <div className="footer-bottom-text">
          <a
            href="https://life5.es/terminos-condiciones/"
            className="underfooter-link"
            target="_blank"
            rel="noopener noreferrer"
          >
            Términos y condiciones
          </a>
          <a
            href="https://life5.es/politica-privacidad/"
            className="underfooter-link"
            target="_blank"
            rel="noopener noreferrer"
          >
            Política de privacidad
          </a>
          <a
            href="https://life5.es/politica-de-cookies/"
            className="underfooter-link"
            target="_blank"
            rel="noopener noreferrer"
          >
            Política de cookies
          </a>
        </div>
        <div className="footer-bottom-logos">
          <img
            src="https://storage.googleapis.com/getlife-bucket-1/public/image/img-new-web/Mastercard.svg"
            loading="lazy"
            alt="ssl-pago"
          />
          <img
            src="https://storage.googleapis.com/getlife-bucket-1/public/image/img-new-web/Visa.svg"
            loading="lazy"
            alt="ssl-pago"
          />
          <img
            src="https://storage.googleapis.com/getlife-bucket-1/public/image/img-new-web/Amex.svg"
            loading="lazy"
            alt="ssl-pago"
          />
          <img
            src="https://storage.googleapis.com/getlife-bucket-1/public/image/img-new-web/ssl-pago.svg"
            loading="lazy"
            alt="ssl-pago"
          />
          <img
            src="https://storage.googleapis.com/getlife-bucket-1/public/image/img-new-web/ssl.png"
            loading="lazy"
            alt="ssl"
          />
          <img
            src="https://storage.googleapis.com/getlife-bucket-1/public/image/img-new-web/sello-confianza.png"
            loading="lazy"
            alt="sello-confianza"
          />
        </div>
      </div>
    </div>
  );
};

export default Landing;
