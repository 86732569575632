import React from "react";

import Typography, {
  VariantType,
} from "../../atoms/Typography/Typography.component";

import "./Birthdate.styles.scss";

import { errorMessage } from "../../../../utils/error";
import InputGeneral from "../InputGeneral/InputGeneral.component";

const correctDate = (date: string | number): string => {
  let newDate: number;
  if (date === "") {
    return date;
  }

  if (typeof date === "string") {
    newDate = parseInt(date);
  } else {
    newDate = date;
  }

  return newDate < 10 ? `0${newDate}` : newDate.toString();
};

const correctYear = (year: string): string => {
  const newYear: number = parseInt(year);

  const actualYear = new Date().getFullYear();

  const minumYear: number = parseInt(
    (actualYear - 74).toString().substring(0, 2)
  );

  if (newYear < minumYear) {
    return `${Math.trunc(actualYear / 100)}${
      newYear < 10 ? `0${newYear}` : newYear
    }`;
  } else if (year.length === 4) {
    return year;
  }
  return `${Math.trunc(actualYear / 100) - 1}${newYear}`;
};

interface BirthdateProps {
  variantTitle: VariantType;
  title: string;
  values: any;
  setValue: (key: string, value: string) => void;
  error: any;
}

const Birthdate: React.FC<BirthdateProps> = ({
  variantTitle,
  title,
  values,
  setValue,
  error,
}) => {
  return (
    <div className="birthdate">
      <Typography
        variant={variantTitle}
        text={title}
        className="birthdate--title"
      />
      <div className="birthdate--selects">
        <InputGeneral
          className={"birthdate--selects__day"}
          disabled={false}
          type="number"
          isNumber
          editValue={correctDate(values["day"])}
          error={errorMessage(error, "day")}
          errorType="landing"
          max={31}
          min={0}
          setterOnBlur={(newValue: string) =>
            setValue("day", correctDate(newValue))
          }
          placeholder="Día"
        />
        <InputGeneral
          className={"birthdate--selects__month"}
          disabled={false}
          type="number"
          isNumber
          max={12}
          min={0}
          editValue={correctDate(values["month"])}
          error={errorMessage(error, "month")}
          errorType="landing"
          placeholder="Mes"
          setterOnBlur={(newValue: string) =>
            setValue("month", correctDate(newValue))
          }
        />
        <InputGeneral
          className={"birthdate--selects__year"}
          disabled={false}
          type="number"
          isNumber
          max={new Date().getFullYear() - 18}
          editValue={values["year"]}
          error={errorMessage(error, "year")}
          errorType="landing"
          setter={(newValue: string) => setValue("year", newValue)}
          setterOnBlur={(newValue: string) =>
            setValue("year", correctYear(newValue))
          }
          placeholder="Año"
        />
      </div>
    </div>
  );
};

export default Birthdate;
