import React from 'react';
// Styles
import './DropdownButton.component.scss';
// Assets
import {ReactComponent as User} from '../../../assets/img/user.svg';

interface DropdownButtonProps {
	children: JSX.Element;
}

const DropdownButton: React.FC<DropdownButtonProps> = ({ children }): JSX.Element => {
	return (
		<div tabIndex={0} className="dropdown__container">
			<div className="dropdown__button">
				<User />
			</div>
			<div className="dropdown__content">{children}</div>
		</div>
	);
};

export default DropdownButton;
