import * as React from "react";
import HttpBrokerRepository from "../../../../api/request/Broker/broker.service";
import BrokerUpdateRequest from "../../../../api/request/Broker/Model/Request/BrokerUpdateRequest.model";
import { ContextGetlife } from "../../../../contexts/ContextGetlife";
import { I18nContext } from "../../../../contexts/i18n.context";
import useForm from "../../../../hooks/useForm.hook";
import { requiredFieldsCompanyInfo } from "../../../../utils/staticData";
import { initialValuesFormCompany } from "../../../../utils/staticData";

const CompanyInformationController = () => {
  const {
    state: { translate },
  } = React.useContext(I18nContext);

  const { setLoading, setShowNotification, token } =
    React.useContext(ContextGetlife);

  const brokerRepository = new HttpBrokerRepository("", token);

  const { error, handleSubmit, setValue, values, setValues } = useForm({
    fetch: (values: BrokerUpdateRequest) => fetchSubmit(values),
    setLoading: setLoading,
    requiredFields: requiredFieldsCompanyInfo,
  });

  const fetchCompanyInfo = async () => {
    try {
      setValues(await brokerRepository.getCompanyInformation());
    } catch (error) {
      setValues(initialValuesFormCompany);
    }
  };

  const fetchSubmit = async (values: BrokerUpdateRequest) => {
    const initialLogo = values.logo;
    values.logo = values.logo.search("data") !== -1 ? values.logo : "";
    try {
      await brokerRepository.updateBroker(values);
      values.logo = initialLogo;
      setLoading(false);
      setShowNotification({
        message: translate(`companyInfo.form.success`),
        status: true,
        type: "success",
      });
    } catch (error: any) {
      let message = translate(`errors.companyInfo.${error.message}`);
      if (error.name === "TypeError") {
        message = translate(`errors.companyInfo.maximumBytes`);
      }
      setLoading(false);
      setShowNotification({
        message: message,
        status: true,
        type: "error",
        autoHideDuration: 10000,
      });
    }
  };

  React.useEffect(() => {
    (async () => {
      await fetchCompanyInfo();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { error, handleSubmit, setValue, values, translate };
};

export default CompanyInformationController;
