import { GlobalIcon } from "get-life-storybook-ts";
import "./DownloadableFiles.scss";
import { useContext } from "react";
import { I18nContext } from "../../../contexts/i18n.context";

interface DownloadableFilesProps {
  downloadablePdfs: [
    {
      label: string;
      href: string;
    }
  ];
}

const DownloadableFiles = ({
  downloadablePdfs,
}: DownloadableFilesProps): JSX.Element => {
  const {
    state: { translate },
  } = useContext(I18nContext);

  return (
    <div className="downloadableFiles">
      {downloadablePdfs && downloadablePdfs.map((pdf) => (
        <a
          className="downloadableFiles__file"
          href={pdf.href}
          key={pdf.label + pdf.href}
          target="__blank"
        >
          <GlobalIcon iconName="DownloadIcon" size="XS" color="#423EF4" />
          <span>{translate(pdf.label)}</span>
        </a>
      ))}
    </div>
  );
};

export default DownloadableFiles;
