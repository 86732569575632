/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate } from "react-router";
import * as FullStory from "@fullstory/browser";
import HamburgerMenu from "react-hamburger-menu";
import "./header.scss";

import { ContextGetlife } from "../../../contexts/ContextGetlife";
import HttpBrokerRepository from "../../../api/request/Broker/broker.service";
import NavBar from "../NavBar/NavBar.component";
import WelcomeEmployee from "../../Rebranding/organisms/Modal/WelcomeEmployee/WelcomeEmployee.component";
import HttpTrackingRepository from "../../../api/request/Tracking/tracking.service";
import { I18nContext } from "../../../contexts/i18n.context";

export default function Header({ children }) {
  const {
    broker,
    brokerId,
    rol,
    setBroker,
    setBrokerId,
    setShowNotification,
    setStatus,
    setStatusEmployee,
    setToken,
    status,
    token,
  } = useContext(ContextGetlife);

  const {
    state: { translate },
  } = useContext(I18nContext);


  const location = useLocation();
  const navigate = useNavigate();

  const brokerRepository = new HttpBrokerRepository();
  const trackingRepository = new HttpTrackingRepository();

  const actualUrl = location.pathname;

  const [openMenu, setOpenMenu] = useState(false);

  const handleMenu = () => setOpenMenu(!openMenu);

  const isActiveLink = (url) =>
    actualUrl.includes(url) ? "header-activeLi" : "header-noActiveLi ";

  const logout = (error) => {
    trackingRepository.add({
      eventType: "LOGOUT",
      eventDetails: [
        {
          token: token,
          brokerEmployeeId: brokerId,
          fromURL: actualUrl,
          method: error || "BUTTON",
        },
      ],
    });
    
    setBroker(false);
    setBrokerId("");
    setToken("");
    setStatus("");
  };

  const fetchFullStory = async (broker) => {
    try {
      const user = await brokerRepository.getUserFullStory(broker.id);
      if (!user.customVars.brokerId_real) {
        await brokerRepository.updateFullStoryUser(broker.id, {
          ...user,
          customVars: {
            ...user.customVars,
            brokerId_real: { realVal: broker.brokerageId },
            brokerId: broker.brokerageId,
          },
        });
      }

      if (!user.email) {
        await brokerRepository.updateFullStoryUser(broker.id, {
          ...user,
          email: broker.email,
        });
      }
    } catch (e) {
      FullStory.identify(broker.id, {
        brokerId: broker.brokerageId,
        email: broker.email,
      });
    }
  };

  const fetch = async () => {
    try {
      const broker = await brokerRepository.getBroker(token);

      setBroker(broker);
      setBrokerId(broker.id);
      setStatus(broker.status);
      setStatusEmployee(broker.brokerEmployeeStatus);
  
      await fetchFullStory(broker);
    } catch (e) {
      if (e.code !== 400) {
        if (e.code === 401) {
          logout("ERROR");
        } else if (e.code === 404) {
          navigate("/not-found");
        } else {
          setShowNotification({
            message: translate("errors.header.message"),
            status: true,
            type: "error",
            autoHideDuration: 10000,
            link: (
              <a
                style={{
                  color: "white",
                }}
                href="mailto:broker@life5.com"
              >
                {" "}
                product@life5.com
              </a>
            ),
          });
        }
      }
    }
  };

  useEffect(() => {
    (async () => {
      setOpenMenu(false);
      await fetch();
    })();
  }, [actualUrl, status, token]);

  return (
    <>
      <WelcomeEmployee />
      <header className="header-mainContainer">
        <div className="header-container">
          <div className="header-logosContainer">
            {broker && broker.logoFilename ? (
              <>
                <img
                  src={broker.logoFilename}
                  alt="logo del broker"
                  className="header-brokerLogo"
                />
                <span className={"header-x"}>by</span>
              </>
            ) : broker ? (
              <>
                <p className={"header-brokerName"}>{broker.brokerageName}</p>
                <span className={"header-x"}>by</span>
              </>
            ) : (
              ""
            )}
            <img src="https://storage.googleapis.com/getlife-bucket-1/public/image/life5/Life_5_Logo_BG.svg" alt="GetLife" className="header-getLifeLogo" />
          </div>
          <div className="header-hamburguerContainer">
            <HamburgerMenu
              isOpen={openMenu}
              menuClicked={handleMenu}
              width={18}
              height={15}
              strokeWidth={1}
              rotate={0}
              color="black"
              borderRadius={0}
              animationDuration={0.5}
            />
          </div>

          <NavBar logout={logout} isActiveLink={isActiveLink} type="laptop" />
        </div>
      </header>

      {openMenu && (
        <div className="header-navBurguerContainer">
          <NavBar logout={logout} isActiveLink={isActiveLink} type="mobile" />
        </div>
      )}
      {children}
    </>
  );
}
