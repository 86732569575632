import { useContext } from "react";
import { Link } from "react-router-dom";
import { ContextGetlife } from "../../../contexts/ContextGetlife";
import { I18nContext } from "../../../contexts/i18n.context";
import { mapRoutes } from "../../../Routes/routes";
import DropdownButton from "../DropdownButton/DropdownButton.component";

const NavBar = ({ isActiveLink, logout, type }) => {
  const { status, rol } = useContext(ContextGetlife);
  const {
    state: { translate },
  } = useContext(I18nContext);

  const classes = {
    mobile: ["navBurguer", "linksContainerBurguer", "noActiveLiBurguer"],
    laptop: ["nav", "linksContainer", "noActiveLi"],
  };
  return (
    <nav className={`header-${classes[type][0]}`}>
      <ul className={`header-${classes[type][1]}`}>
        {status !== "questionnaire_pending" &&
          mapRoutes.allowedRoutes(rol).map(
            (route, index) =>
              route.title && (
                <li
                  key={`header-link-${index}`}
                  className={isActiveLink(route.path)}
                >
                  <Link to={route.path} className="header-link">
                    {translate(route.title)}
                  </Link>
                </li>
              )
          )}
          
        <li className={`header-${classes[type][2]} `}>
          <DropdownButton>
            {rol &&
              !rol.includes("ROLE_BROKER_EMPLOYEE") &&
              status !== "questionnaire_pending" && (
                <Link to="/company_info" className="header-link">
                  {translate("navbar.link.companyInfo")}
                </Link>
              )}
            <Link onClick={() => logout()} to="/login" className="header-link">
              {translate("navbar.link.logout")}
            </Link>
          </DropdownButton>
        </li>
      </ul>
    </nav>
  );
};

export default NavBar;
