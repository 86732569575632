import * as React from "react";

import numberFormat from "../../../../utils/numberFormat";

import useForm from "../../../../hooks/useForm.hook";
import { ContextGetlife } from "../../../../contexts/ContextGetlife";
import {
  GUARANTEE,
  initialValuesLandingCalcStep1,
  initialValuesLandingCalcStep2,
  requiredFieldsFormCalcBirth,
  requiredFieldsFormCalcChecked,
} from "../../../../utils/staticData";

import { emptyObjects, validateBirthdate } from "../../../dashboard/Dashboard";
import * as FullStory from '@fullstory/browser';
import { I18nContext } from "../../../../contexts/i18n.context";
import HttpLeadRepository from "../../../../api/request/Lead/lead.service";
import {
  GoogleContext,
} from "../../../../contexts/GoogleTagManager.context";
import OrganicResponse from "../../../../api/request/Lead/Model/Response/OrganicResponse.model";
import PricesResponse, {
  PriceByCapitalResponse,
} from "../../../../api/request/Lead/Model/Response/PricesResponse.model";
import { CalculatorProps } from "./Calculator.component";
import HttpTarifierRepository from "../../../../api/request/Tarifier/tarifier.service";
import CapitalLimitsResponse, {
  AvailableTraits,
  CapitalLimits,
} from "../../../../api/request/Tarifier/Model/Response/CapitalLimitsResponse.model";
import { IntentType, PDF } from "../../../../api/request/Lead/Model/Response/CarrierPricesResponse.model";
import { CoverageType } from "../../../../contexts/GoogleTagManager.context";
import { getTrackingUtms } from "../../../../api/request/apiLeadTrackingGet";

interface RangeProps {
  initial: number;
  final: number;
  step: number;
}

interface SelectI<Type> {
  label: string | undefined;
  value: Type;
}

interface SelectedValueI<Type> {
  (value: Type, selectMap: SelectI<Type>[]): SelectI<Type>;
}

export type Gender = "Male" | "Female";

export const range = ({
  initial,
  final,
  step,
}: RangeProps): SelectI<number>[] => {
  return Array.from({ length: (final - initial) / step + 1 }, (_, i) => ({
    label: numberFormat(initial + i * step, "capital"),
    value: initial + i * step,
  }));
};

export const selectedValue: SelectedValueI<unknown> = (value, selectMap) =>
  selectMap.filter((selected) => selected.value === value)[0] || value;

export const CalculatorController = ({ brokerId }: CalculatorProps) => {
  const { broker, setShowNotification, setLoading, token } =
    React.useContext(ContextGetlife);

  const {
    state: { translate },
  } = React.useContext(I18nContext);

  const { handleTrackerQuestion } = React.useContext(GoogleContext);

  const [limits, setLimits] = React.useState<CapitalLimits>(
    {} as CapitalLimits
  );
  const [traits, setTraits] = React.useState<AvailableTraits>(
    {} as AvailableTraits
  );

  const [capital, setCapital] = React.useState<number>(0);

  const [leadId, setLeadId] = React.useState<string>("");

  const [insurancePrice, setInsurancePrice] =
    React.useState<PriceByCapitalResponse>();

  const [capitalSelect, setCapitalSelect] = React.useState<
    Array<{ label: string | undefined; value: number }>
  >([]);

  const [birthdate, setBirthdate] = React.useState<string>("");

  const [stepCalculator, setStepCalculator] = React.useState<number>(1);

  const [pdfUrl, setPdfUrl] = React.useState<string | undefined>();

  const pdfElement = React.useRef<HTMLAnchorElement>();

  const leadRepository = new HttpLeadRepository();
  const tarifierRepository = new HttpTarifierRepository(token);

  const { values, setValue, setValues, error, handleSubmit } = useForm({
    fetch: (newValues) => fetchSubmitOrganic(newValues),
    initialValues: initialValuesLandingCalcStep2,
    setLoading,
    requiredFields: requiredFieldsFormCalcChecked,
  });

  const formLimits = useForm({
    fetch: (newValues) => fetchCapitalLimits(newValues),
    initialValues: initialValuesLandingCalcStep1,
    setLoading,
    requiredFields: requiredFieldsFormCalcBirth,
  });

  const areEmptyValues = (initialData: any): boolean => {
    if (!initialData) {
      return false;
    }
    return Object.values(initialData).every((value) => value);
  };

  const fetchCapitalLimits = async (values: any) => {
    const birth = `${values.year}-${values.month}-${values.day}`;
    const birthdate = validateBirthdate(birth);
    try {
      let response: CapitalLimitsResponse =
        await tarifierRepository.getCapitalLimits(birthdate || "");
      const { minCapital, maxCapital, availableTraits } = response;

      if (availableTraits.DEATH === false && availableTraits.DISABILITY === false) {
        setShowNotification({
          message: translate("errors.limits.ageRange"),
          status: true,
          type: "error",
          autoHideDuration: 5000,
        });
      } else {
        setLimits({
          maxCapital,
          minCapital,
          step: maxCapital === 500000 ? 25000 : 10000,
        });
        setTraits({
          DEATH: availableTraits.DEATH,
          DISABILITY: availableTraits.DISABILITY,
        });
        setBirthdate(birth);
        if ("checkedIPA" in values) {
          delete values.checkedIPA;
        }
        setStepCalculator(2);
      }
    } catch (error: any) {
      setShowNotification({
        message: translate(`errors.calculator.${error.message}`),
        status: true,
        type: "error",
      });
    }
    setLoading(false);
  };

  const fetchSubmitOrganic = async (values: any, finish?: boolean) => {
    try {
      const response: OrganicResponse = await leadRepository.postOrganic({
        birthdate,
        brokerId,
        capital: values.capital.value,
        email: values.email,
        gender: values.gender,
        insuranceType: values.checkedIPA ? "premium" : "basic",
        phone: values.phone !== false ? values.phone : "",
        zipCode: formLimits.values.zipCode,
        intention: "loan",
      });
      const { leadId, customerURL } = response;
      if (!("checkedIPA" in values)) {
        setValues({ ...values, checkedIPA: false });
      }
      if (finish) {
        window.location.href = customerURL;
      }
      setCapital(values.capital.value);
      setLeadId(leadId);
      const price: PricesResponse = await leadRepository.getPrice(leadId);
      const priceByCapital: PriceByCapitalResponse =
        await leadRepository.getPriceByCapital(leadId, values.capital.value);
      setInsurancePrice(priceByCapital);
      await downloadPDF(leadId);
      setStepCalculator(3);
      setLoading(false);
      const utms = await getTrackingUtms(leadId);
      const eventData = {
        calculatedCapital: capital,
        calculatedPremium: price.monthlyPrice,
        coverage: (values.checkedIPA ? GUARANTEE["premium"] : GUARANTEE["basic"]) as CoverageType,
        email: values.email,
        gender: values.gender,
        intent: "loan" as IntentType,
        leadId: leadId,
        page: window.location.href,
        phone: values.phone !== false ? values.phone : "",
        postalCode: formLimits.values.zipCode,
        brokerEmployeeId: brokerId,
        brokerId: typeof broker !== "boolean" ? broker.brokerageId : "",
        platform: "Broker",
        utm_source: utms.utm_source ?? "brokerapp",
        utm_medium: utms.utm_medium ?? (typeof broker !== "boolean" ? broker.brokerageId : ""),
        utm_campaign: utms.utm_campaign ?? brokerId,
        utm_content: utms.utm_content,
        utm_term: utms.utm_term,
        utm_test: utms.utm_test,
        fbclid: utms.fbclid,
        gclid: utms.gclid,
        entryPage: utms.entryPage,
        referrerUrl: utms.referrerUrl,
        ABTestVersion: utms.ABTestVersion
      }

      if(response.isFirstIncoming) {
        const event = "leadCreated";

        handleTrackerQuestion({
          type: "FS",
          event: event,
          data: {
            birthDate: new Date(birthdate),
            ...eventData
          }
        });
        handleTrackerQuestion({
          type: "GA",
          data: {
            event: event,
            birthDate: birthdate,
            ...eventData
          },
        });
      }
      const event = "calculatorQuoteGenerated";

      handleTrackerQuestion({
        type: "FS",
        event: event,
        data: {
          birthDate: new Date(birthdate),
          ...eventData
        }
      });
      handleTrackerQuestion({
        type: "GA",
        data: {
          event: event,
          birthDate: birthdate,
          ...eventData
        },
      });

      return response;
    } catch (e: any) {
      setLoading(false);
      setShowNotification({
        message: translate(`errors.landing.${e.message}`),
        status: true,
        type: "error",
        anchorOrigin: { vertical: "top", horizontal: "center" },
        autoHideDuration: 10000,
      });
    }
  };

  React.useEffect(() => {
    if (emptyObjects(limits)) {
      setCapitalSelect(
        range({
          initial: limits.minCapital,
          final: limits.maxCapital,
          step: limits.step,
        }).reverse()
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limits]);

  const downloadPDF = async (lead? : string) => {
    let templates = ["offer-w-base"];
    if (values.checkedIPA) {
      templates = ["offer-w-base", "ipa-any-cause-exclusions"];
    }

    const response: PDF = await leadRepository.downloadPDF(lead ? lead : leadId, templates);
    setPdfUrl(response.pdfUrl);
  };

  const handleSubmitStep2 = async () => {
    const submit = await handleSubmit();
  };

  const handleChangeSwitch = async (newValue: any) => {
    values["checkedIPA"] = newValue.target.checked;
    setLoading(true);
    await fetchSubmitOrganic(values);
    await downloadPDF();
    setLoading(false);
  };

  const handleChangeCapital = async (type: "minus" | "plus") => {
    let newCapital: number;
    if (type === "minus") {
      newCapital = capital - limits.step;
    } else {
      newCapital = capital + limits.step;
    }
    if (limits.maxCapital < newCapital) {
      newCapital = limits.maxCapital;
    } else if (limits.minCapital > newCapital) {
      newCapital = limits.minCapital;
    }
    setValue("capital", { value: newCapital });
    setCapital(newCapital);
    try {
      const response: PriceByCapitalResponse =
        await leadRepository.getPriceByCapital(leadId, newCapital);
      setInsurancePrice(response);
      await downloadPDF();
    } catch (error) {}
  };

  const navigateToQuestions = async () => {
    await fetchSubmitOrganic(values, true);
  };

  return {
    areEmptyValues,
    capital,
    capitalSelect,
    downloadPDF,
    error,
    formLimits,
    handleChangeCapital,
    handleChangeSwitch,
    handleSubmitStep2,
    insurancePrice,
    limits,
    navigateToQuestions,
    pdfElement,
    pdfUrl,
    stepCalculator,
    setStepCalculator,
    setValue,
    traits,
    translate,
    values,
  };
};

export default CalculatorController;
