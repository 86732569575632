import { Input } from "@material-ui/core";
import NumberFormat from "react-number-format";
import MinusIcon from "../../atoms/Icons/MinusIcon.component";
import PlusIcon from "../../atoms/Icons/PlusIcon.component";
import Label from "../../atoms/Label/Label.component";
import "./NumberBox.component.scss";
import "../../atoms/Input/InputBase.styles.scss";
import InfoMessage from "../../atoms/InfoMessage/InfoMessage.component";
import { InputBaseProps } from "../../atoms/Input/InputBase.component";
import { useEffect, useState } from "react";

interface NumberBoxProps extends InputBaseProps {
  add: () => void;
  classNameMinus?: string;
  classNamePlus?: string;
  disabledMinus?: boolean;
  disabledPlus?: boolean;
  error?: string;
  remove: () => void;
  setter?: (value: string) => void;
  setterOnBlur?: (e: string | number) => void;
  suffix: string;
  value: number;
}

const NumberBox: React.FC<NumberBoxProps> = ({
  add,
  className,
  classNameMinus,
  classNamePlus,
  disabled,
  disabledMinus,
  disabledPlus,
  error,
  label,
  name,
  remove,
  setter,
  setterOnBlur,
  suffix,
  value,
}): JSX.Element => {
  const [numberValue, setNumberValue] = useState<string | number>(value);
  useEffect(() => {
    if (value !== numberValue) {
      setNumberValue(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <>
      {!!label && (
        <Label
          label={label}
          className={`${
            disabled ? "inputGetLife-labelAnswered" : "inputGetLife-label"
          } label-number-box`}
        />
      )}
      <div className={`number-box__container ${error && "error"} ${className}`}>
        <MinusIcon
          onClick={() => remove()}
          disabled={disabled || disabledMinus}
          className={classNameMinus}
        />
        <NumberFormat
          className={"number-box__input"}
          customInput={Input}
          value={numberValue}
          disabled={disabled}
          suffix={` ${suffix}`}
          thousandSeparator={"."}
          name={name}
          decimalSeparator={","}
          onBlur={() => {
            setterOnBlur && setterOnBlur(numberValue);
          }}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setNumberValue(event.target.value);
            setter && setter(event.target.value);
          }}
        />

        <PlusIcon
          onClick={() => add()}
          disabled={disabled || disabledPlus}
          className={classNamePlus}
        />
      </div>
      {error && <InfoMessage message={error} type="error" />}
    </>
  );
};

export default NumberBox;
