import React from "react";
import Button, { ButtonProps } from "../ButtonBase/Button.component";
import "./ButtonLink.styles.scss";
interface ButtonLinkProps extends ButtonProps {
  label: string;
  link: string;
}

const ButtonLink: React.FC<ButtonLinkProps> = ({
  buttonType,
  className,
  label,
  link,
}): JSX.Element => {
  return (
    <Button className={`button-link ${className}`} buttonType={buttonType}>
      <a href={link}>{label}</a>
    </Button>
  );
};

export default ButtonLink;
