import { intersection } from "lodash";
import { dateTimeToDate, formatPrice, getValidateEmail } from "./staticData";
import { getValidateStatus, overrideStatusClass } from "./statusLead";
import HttpListRepository from "../api/request/Lists/list.service";
import { getRoles } from "./routesUtils";
import { ROLESTABLE } from "./roles";

const getPrices = (element) => {
  if (element.promotedYearlyPrice !== 0 && element.promotedYearlyPrice !== element.yearlyPrice) {
    return (
      <>
        <p><s>{formatPrice(element.yearlyPrice)}</s></p>
        <p>{formatPrice(element.promotedYearlyPrice)}</p>
      </>
    )
  } else {
     return (
      <>
        <p>{formatPrice(element.yearlyPrice)}</p>
      </>
    )
  }
}

const normalizeData = (data, translate) => {
  const newData = data.map((element) => ({
    ...element,
    email: getValidateEmail(element.email),
    phone: element.phone,
    creationDate: dateTimeToDate(element.creationDate),
    purchaseDate: dateTimeToDate(element.purchaseDate),
    effectDate: dateTimeToDate(element.effectDate),
    yearlyPrice: getPrices(element),
    overrideClasses: {
      status: overrideStatusClass(element.status),
    },
    status: getValidateStatus(element.status, translate),
    backStatus: element.status,
    monthlyPrice: element.prima,
  }));
  return newData;
};

const normalizeDataEmployees = (data, translate) => {
  const newData = data.map((element) => ({
    ...element,
    email: getValidateEmail(element.email),
    phone: element.phone,
    createdAt: dateTimeToDate(element.createdAt),
    nameComplete: `${element.name} ${element.lastname}`,
    overrideClasses: {
      status: overrideStatusClass(element.status),
    },
    rol: translate(ROLESTABLE[intersection(element.roles, getRoles())[0]]),
  }));
  return newData;
};

const normalize = (data, type, translate) => {
  let types = {
    projects: normalizeData(data, translate),
    policies: normalizeData(data, translate),
    employees: normalizeDataEmployees(data, translate)
  }

  return types[type];
  
};

export const fetchData = async ({
  endDate,
  indexPage,
  omniValue,
  ordering,
  pageSize,
  reset,
  setData,
  setIndexPage,
  setShowNotification,
  setTotalItems,
  startDate,
  token,
  translate,
  type,
}) => {
  const listRepository = new HttpListRepository(token);
  try {
    const data = await listRepository.getListByType({
      endDate,
      indexPage: reset ? 1 : indexPage,
      omniValue,
      ordering,
      pageSize,
      startDate,
      type,
    });
    setData(normalize(data.items, type, translate));
    setTotalItems(data.totalItems);
    if (reset) {
      setIndexPage(1);
    }
  } catch (error) {
    // setData([]);
    if (error.code !== 403)
      setShowNotification({
        message: error.message,
        type: "error",
        status: true,
      });
  }
};
