import React from "react";
import InfoMessage, {
  InfoMessageProps,
} from "../../atoms/InfoMessage/InfoMessage.component";
import RadioInput, {
  RadioInputProps,
} from "../../atoms/Input/RadioInput/RadioInput.component";

interface RadioGroupProps {
  arrayRadio: RadioInputProps[];
  className?: string;
  classNameContainer?: string;
  classNameLabel?: string;
  disabledAll?: boolean;
  editValue?: string;
  setter: (value: string) => void;
  error?: string;
  errorType?: InfoMessageProps["type"] | undefined;
}

const RadioGroup: React.FC<RadioGroupProps> = ({
  arrayRadio,
  className,
  classNameContainer,
  classNameLabel,
  disabledAll,
  editValue,
  error,
  errorType,
  setter,
}) => {
  const [radioValue, setRadioValue] = React.useState<string | undefined>(
    editValue
  );

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setRadioValue(value);
    if (setter) {
      setter(value);
    }
  };
  return (
    <>
      <div className={classNameContainer} onChange={handleChange}>
        {arrayRadio.map(({ label, id, value, name, disabled }) => (
          <RadioInput
            label={label}
            id={id}
            className={className}
            classNameLabel={classNameLabel}
            disabled={disabled || disabledAll}
            value={value}
            name={name}
            checked={value === radioValue}
          />
        ))}
      </div>
      {error && error.length && (
        <InfoMessage message={error} type={errorType ? errorType : "error"} />
      )}
    </>
  );
};

export default RadioGroup;
