const prepareListDaysOptions = function () {
    const days = [...Array(31).keys()];
    const optionsDays = [];
    days.forEach((element) =>
        optionsDays.push({
            value: String(element + 1),
            label: String(element + 1),
        })
    );

    return optionsDays;
}

export default prepareListDaysOptions;