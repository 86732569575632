import React from "react";

import { CheckBoxProps } from "../CheckBox/Checkbox.component";
import "./Switch.styles.scss";

interface SwitchProps extends CheckBoxProps {
  labelRight?: string;
  labelLeft?: string;
}

const Switch: React.FC<SwitchProps> = ({
  checked,
  className,
  classNameLabel,
  disabled,
  labelLeft,
  labelRight,
  onChange,
  id,
}) => {
  return (
    <>
      <span className="label-left">{labelLeft}</span>
      <input
        checked={checked}
        onChange={onChange}
        className={`react-switch-checkbox ${className} `}
        id={id}
        disabled={disabled}
        type="checkbox"
      />
      <label className={`react-switch-label ${classNameLabel}`} htmlFor={id}>
        <span
          className={`react-switch-button ${
            disabled && "react-switch-disabled"
          }`}
        />
      </label>
      <span className="label-right">{labelRight}</span>
    </>
  );
};

export default Switch;
