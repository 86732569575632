// @ts-nocheck
import * as React from "react";
import HttpBrokerRepository from "../../../../../api/request/Broker/broker.service";
import BrokerCompleteRegistrationRequest from "../../../../../api/request/Broker/Model/Request/BrokerCompleteRegistrationResquest.model";
import { ContextGetlife } from "../../../../../contexts/ContextGetlife";
import { I18nContext } from "../../../../../contexts/i18n.context";
import useForm from "../../../../../hooks/useForm.hook";
import { requiredFieldsEmployee } from "../../../../../utils/staticData";

const EmployeeFormController = () => {
  const {
    state: { translate },
  } = React.useContext(I18nContext);

  const {
    setLoading,
    statusEmployee,
    brokerId,
    token,
    setShowNotification,
    setStatusEmployee,
    broker,
  } = React.useContext(ContextGetlife);

  const [showModal, setShowModal] = React.useState<boolean>(false);

  const brokerRepository = new HttpBrokerRepository(brokerId, token);

  const fetch = async (values: BrokerCompleteRegistrationRequest) => {
    try {
      await brokerRepository.completeRegistration(values);
      setShowModal(false);
      const broker = await brokerRepository.getBroker(token);
      setStatusEmployee(broker.brokerEmployeeStatus);
      setShowNotification({
        message: translate(`employees.form.success`),
        status: true,
        type: "success",
        anchorOrigin: { vertical: "top", horizontal: "center" },
      });
    } catch (error) {
      setShowNotification({
        message: translate(`errors.employees.${error.message}`),
        status: true,
        type: "error",
        anchorOrigin: { vertical: "top", horizontal: "center" },
        autoHideDuration: 10000,
      });
    }
    setLoading(false);
  };

  const { values, setValue, handleSubmit, error, setValues } = useForm({
    fetch: (values) => fetch(values),
    setLoading,
    requiredFields: requiredFieldsEmployee,
  });
  React.useEffect(() => {
    setShowModal(statusEmployee === "guested");
  }, [statusEmployee]);

  React.useEffect(() => {
    setValues({
      name: broker.name,
      lastname: broker.lastname,
      phone: broker.personalPhone,
    });
  }, [broker]);

  return {
    error,
    handleSubmit,
    setShowModal,
    setValue,
    showModal,
    translate,
    values,
  };
};

export default EmployeeFormController;
