import Typography from "../../atoms/Typography/Typography.component";
import * as React from "react";
import "./FAQuestion.style.scss";

interface FAQuestionsProps {
  color?: string;
  title: string;
  text: string;
  variantTitle?: "paragraph" | "bold";
  variantText?: "paragraph" | "h5";
}

const FAQuestion: React.FC<FAQuestionsProps> = ({
  color,
  title,
  text,
  variantTitle = "bold",
  variantText = "paragraph",
}): JSX.Element => {
  return (
    <div className="question">
      <div className="questionTitle">
        <Typography variant={variantTitle} text={title} />
      </div>
      <div className={`questionAnswer ${color}`}>
        <Typography variant={variantText} text={text} />
      </div>
    </div>
  );
};

export default FAQuestion;
