export default function apiCheckOutFinish( token, leadId ) {
	const bearer = 'Bearer ' + token;
	return fetch(
		process.env.REACT_APP_CUSTOMER_APP_URL + `api/lead/payment/finish/${leadId}/succeeded`,

		{
			method: 'POST',
			headers: {
				Authorization: bearer,
			},
		}
	).then((response) => response.json());
}
