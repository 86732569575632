import React from "react";
import RadioGroup from "../../../molecules/RadioGroup/RadioGroup.component";
import FormOtherBeneficiaries from "../FormOtherBeneficiaries/FormOtherBeneficiaries.component";
import { FormBeneficiariesProps } from "../FromInterface.model";

const FormBeneficiaries: React.FC<FormBeneficiariesProps> = ({
  beneficiaries,
  values,
  translate,
  setValue,
  disabled,
}) => {
  const [otherBeneficiaries, setOtherBeneficiaries] = React.useState<string>(
    values.beneficiaries.value
  );

  return (
    <>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <RadioGroup
          arrayRadio={beneficiaries}
          disabledAll={disabled}
          editValue={values.beneficiaries && values.beneficiaries.value}
          setter={(newValue: string) => {
            const newBene = beneficiaries.filter(
              (obj) => obj.value === newValue
            )[0];
            setValue("beneficiaries", newBene);
            setOtherBeneficiaries(newValue);
          }}
        />
        {otherBeneficiaries === "other" && (
          <FormOtherBeneficiaries
            translate={translate}
            values={values}
            disabled={disabled}
            setValue={setValue}
          />
        )}
      </div>
    </>
  );
};

export default FormBeneficiaries;
