import * as React from "react";
import Typography from "../../atoms/Typography/Typography.component";
import ContactBubble, {
  ContactBubbleType,
} from "../ContactBubble/ContactBubble.component";

export interface Bubble {
  title: string;
  text: string;
  contact: { text?: string; type: ContactBubbleType };
}

interface ContactBubbleProps {
  title: string;
  arrayBubbles: Bubble[];
  variantTitle?: "h1" | "bold" | "h2";
}

const GetContact: React.FC<ContactBubbleProps> = ({
  title,
  arrayBubbles,
  variantTitle = "h2",
}): JSX.Element => {
  return (
    <>
      <div className="getContactSection">
        <Typography variant={variantTitle} text={title} />
        <div className="bubblesSection">
          {arrayBubbles.map((bubble: Bubble) => (
            <>
              <ContactBubble
                title={bubble.title}
                text={bubble.text}
                contact={bubble.contact}
              ></ContactBubble>
            </>
          ))}
        </div>
      </div>
    </>
  );
};

export default GetContact;
