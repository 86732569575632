// @ts-nocheck
// Vendors
import { useEffect, useRef, useState } from "react";
// Styles
import "./UploadFile.component.scss";
// Icons
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "../../Rebranding/atoms/Buttons/IconButton/IconButton.component";

interface UploadFileProps {
  label: string;
  name: string;
  setFile: (value1: string, value2: string) => void;
  title: string;
  value?: string;
}

const UploadFile: React.FC<UploadFileProps> = ({
  label,
  name,
  setFile,
  title,
  value,
}): JSX.Element => {
  const [imageUrl, setImageUrl] = useState(value);
  const ref = useRef<HTMLInputElement>();

  useEffect(() => {
    setImageUrl(value);
  }, [value]);

  const handleFile = (e: any) => {
    const imageBlob = e.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(imageBlob);
    reader.onload = () => {
      setFile(name, reader.result);
    };
    setImageUrl(URL.createObjectURL(imageBlob));
  };

  const handleDeleteFile = () => {
    setFile(name, "");
    setImageUrl("");
    ref.current.value = "";
  };

  const handleEditFile = () => {
    ref.current.click();
  };

  return (
    <div
      className={`upload-file__container ${
        !!imageUrl && !!imageUrl.length ? "withImage" : ""
      }`}
    >
      <div
        className="upload-file__item"
        style={imageUrl && !!imageUrl.length ? { display: "none" } : {}}
      >
        <span>{label}</span>
        <label htmlFor="file">{title}</label>
        <input
          type="file"
          id="file"
          accept="image"
          onChange={handleFile}
          ref={ref}
        />
      </div>
      {imageUrl && !!imageUrl.length && (
        <>
          <div className="upload-file__buttons">
            <IconButton
              icon={<EditIcon />}
              onClick={() => handleEditFile()}
              className="upload-file__buttons__edit"
            />
            <IconButton
              icon={<DeleteIcon />}
              onClick={() => handleDeleteFile()}
              className="upload-file__buttons__delete"
            />
          </div>
          <div className="upload-file__preview">
            <img src={imageUrl} alt="file-preview" />
          </div>
        </>
      )}
    </div>
  );
};

export default UploadFile;
