import { IconProps } from "./IconProps.interface";

interface GroupIconsProps {
  icons: IconProps[];
  className: string;
}

const GroupIcons: React.FC<GroupIconsProps> = ({
  icons,
  className,
}): JSX.Element => {
  return (
    <div className={className}>
      {icons.map((icon) => (
        <div>{icon as React.ReactNode}</div>
      ))}
    </div>
  );
};

export default GroupIcons;
