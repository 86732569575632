import * as React from "react";
import Typography, {
  VariantType,
} from "../../atoms/Typography/Typography.component";

import "./InformationSecure.styles.scss";

interface Item {
  text: string;
}

interface InformationSecureProps {
  subtitle: string;
  title: string;
  variantTitle?: VariantType;
  list: Item[];
  className?: string;
  classNameList?: string;
}

const InformationSecure: React.FC<InformationSecureProps> = ({
  subtitle,
  title,
  variantTitle = "h2",
  list,
  className,
  classNameList,
}) => {
  return (
    <div className={`information-content ${className}`}>
      <div>
        <Typography
          className="information-content--title"
          variant={variantTitle}
          text={title}
        />
        <Typography
          className="information-content--subtitle"
          variant={variantTitle}
          text={subtitle}
        />
      </div>
      
      <ul className={`information-list ${classNameList}`}>
        {list.map((item: Item, index: number) => (
          <li tabIndex={index}>
            <Typography variant="paragraph" text={item.text} />
          </li>
        ))}
      </ul>
    </div>
  );
};

export default InformationSecure;
