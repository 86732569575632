import * as React from "react";
import * as ES from "../resources/i18n/es.json";
import * as FR from "../resources/i18n/fr.json";
import * as EN from "../resources/i18n/en.json";
import * as PT from "../resources/i18n/pt.json";
import * as IT from "../resources/i18n/it.json";

export const translations: any = {
  es: ES,
  fr: FR,
  en: EN,
  pt: PT,
  it: IT
};

const userLanguage: string = process.env.REACT_APP_DOMAIN_LOCALE || "es";

const getTranslate = (langCode: string) => (key: string) =>
  translations[langCode].default[key] || key;

const defaultValue = {
  langCode: userLanguage,
  translate: getTranslate(userLanguage),
};

interface LocaleState {
  langCode: string;
  translate: (key: string) => string;
}

interface ContextProps {
  state: LocaleState;
  dispatch: ({ type }: { type: string; payload: string }) => void;
}

export const I18nContext = React.createContext({} as ContextProps);

export const WithI18n = ({ children }: any) => {
  const reducer = (state: any, action: { type: string; payload: string }) => {
    if ("setLanguage" === action.type) {
      return {
        langCode: action.payload,
        translate: getTranslate(action.payload),
      };
    } else {
      return { ...defaultValue };
    }
  };

  const [state, dispatch] = React.useReducer(reducer, defaultValue);

  return (
    <I18nContext.Provider value={{ state, dispatch }}>
      {children}
    </I18nContext.Provider>
  );
};
