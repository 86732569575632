import React from "react";
import numberFormat from "../../../../utils/numberFormat";
import Typography, {
  VariantType,
} from "../../atoms/Typography/Typography.component";

interface NumberAnimateProps {
  start?: number;
  timer?: number;
  end: number | null;
  variant?: VariantType;
  format?: boolean;
}

const NumberAnimated: React.FC<NumberAnimateProps> = ({
  start = 0,
  end,
  timer = 10,
  variant = "span",
  format,
}): JSX.Element => {
  const [value, setValue] = React.useState<number>(0);
  const ref = React.useRef<number>(start);

  const accumulator: number | null = end && end / 200;

  const updateValue = () => {
    if (end !== null && accumulator) {
      const result = Math.ceil(ref.current + accumulator);
      if (result > end) return setValue(end);
      setValue(result);
      ref.current = result;
    }
    setTimeout(updateValue, timer);
  };

  React.useEffect(() => {
    let isMounted: boolean = true;
    if (isMounted) {
      updateValue();
    }
    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [end, start]);

  return (
    <Typography
      variant={variant}
      text={
        format ? (numberFormat(value as number, "capital") as string) : value
      }
    />
  );
};

export default NumberAnimated;
