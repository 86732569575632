// Components
import { CustomTable } from "../components/CustomTable/custom-table.component";
// Styles
import "./Projects.scss";
// Controller
import ProjectController from "./controllers/Project.controller";
import EditProjects from "./EditProjects";

const Projects = () => {
  const {
    broker,
    data,
    modalData,
    recoverProject,
    setData,
    setShowModal,
    showModal,
    tableProps,
    token,
  } = ProjectController();

  return (
    <div className="project__container">
      <CustomTable {...tableProps} />
      <EditProjects
        className="edit-modal"
        classNameContent="edit-modal__content"
        classNameHeader="edit-modal__title"
        data={data}
        disabledButton={tableProps.disabledButton}
        id="edit-modal"
        modalData={modalData}
        recoverProject={recoverProject}
        setData={setData}
        setShowModal={setShowModal}
        showModal={showModal}
        token={token}
        broker={broker}
      />
    </div>
  );
};

export default Projects;
