import React from "react";
import PlacesAutocomplete, {
  geocodeByPlaceId,
} from "react-places-autocomplete";
import InfoMessage from "../../Rebranding/atoms/InfoMessage/InfoMessage.component";
import "./autocompleteGoogle.scss";

export default class AutocompleteGoogle extends React.Component {
  constructor(props) {
    super(props);
    this.state = { address: this.props.initialVal };
  }

  handleChange = (address) => {
    delete this.initialVal;
    delete this.state.direction;
    this.setState({ address });
  };

  handleSelect = async (address, placeId) => {
    this.setState({ address });
    const [place] = await geocodeByPlaceId(placeId);
    const { long_name: route = "" } =
      place.address_components.find((c) => c.types.includes("route")) || {};
    const { long_name: number = "" } =
      place.address_components.find((c) => c.types.includes("street_number")) ||
      {};
    const { long_name: postalCode = "" } =
      place.address_components.find((c) => c.types.includes("postal_code")) ||
      {};
    const { long_name: locality = "" } =
      place.address_components.find((c) => c.types.includes("locality")) || {};
    const { long_name: province = "" } =
      place.address_components.find((c) =>
        c.types.includes("administrative_area_level_2")
      ) || {};
    const direction = number ? route + ", " + number : route;

    this.setState({ direction });
    this.props.handleDirection({
      addressLine: direction,
      city: locality,
      province,
      zipcode: postalCode && postalCode,
    });
  };

  render() {
    return (
      <PlacesAutocomplete
        value={this.state.direction ? this.state.direction : this.state.address}
        onChange={this.handleChange}
        onSelect={this.handleSelect}
        onBlur={this.props.onBlur}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div className="autocomplete-container">
            <label style={this.props.disabled ? { color: "grey" } : {}}>
              {this.props.label}
            </label>
            <input
              {...getInputProps({
                placeholder: this.props.translate("poststack.placeholder.address"),
                className: "location-search-input",
              })}
              disabled={this.props.disabled}
            />
            <div className="autocomplete-dropdown-container">
              {loading && <div>Loading...</div>}
              {suggestions.map((suggestion, index) => {
                const className = suggestion.active
                  ? "suggestion-item--active"
                  : "suggestion-item";
                // inline style for demonstration purpose
                const style = suggestion.active
                  ? { backgroundColor: "#fafafa", cursor: "pointer" }
                  : { backgroundColor: "#ffffff", cursor: "pointer" };
                return (
                  <div
                    key={index}
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style,
                    })}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                );
              })}
            </div>
            {this.props.error && (
              <InfoMessage
                message={this.props.error}
                type={this.props.errorType ? this.props.errorType : "error"}
              />
            )}
          </div>
        )}
      </PlacesAutocomplete>
    );
  }
}
