import React from "react";
import ButtonLink from "../../atoms/Buttons/ButtonLink/ButtonLink.component";
import Typography from "../../atoms/Typography/Typography.component";
import "./ContactSection.styles.scss";

interface ContactSectionProps {
    variantTitle: 
    | "h2"
    | "h3"
    | "h4"
    | "h5"
    | "h6";
    variantText: 
    | "paragraph"
    | "bold";
    title: string;
    text: string;
    labelContact: string;
    link: string;
}

const ContactSection: React.FC<ContactSectionProps> = ({variantTitle, variantText, title, text, labelContact, link}) => {
    return (
        <section className="contactSection">
            <Typography variant={variantTitle} text={title} className="contactSection--title"/>
            <Typography variant={variantText} text={text} className="contactSection--text"/>
            <ButtonLink link={link} label={labelContact} buttonType="secondary" className="contactSection--link"/>
        </section>
    )
}

export default ContactSection; 