import { PopupModal } from "react-calendly";
import { requiredFieldsPostStack } from "../../../../utils/staticData";
import Button from "../../atoms/Buttons/ButtonBase/Button.component";
import IconButton from "../../atoms/Buttons/IconButton/IconButton.component";
import CalendarIcon from "../../atoms/Icons/CalendarIcon.component";
import PhoneIcon from "../../atoms/Icons/PhoneIcon.component";
import Typography from "../../atoms/Typography/Typography.component";
import PostStackQuestion from "../../organisms/PostStackQuestions/PostStackQuestion.component";
import "./PostStack.styles.scss";
import PostStackController from "./PostStack.controller";

const PostStack = (): JSX.Element => {
  const {
    activeForm,
    areInitialValues,
    emailDisabled,
    handleIsAnswer,
    initialValues,
    isAnswered,
    navigate,
    petitions,
    popUpCalendly,
    postStackQuestionTypes,
    selectData,
    setEmail,
    setPopUpCalendly,
    translate,
  } = PostStackController();

  const DOMAIN = process.env.REACT_APP_DOMAIN_LOCALE;

  return (
    <div className="postStack-mainContainer">
      <div className="postStack-container">
        <header className="postStack-headContainer">
          <Typography variant="h1" text={translate("postStack.header.title")} />
          <Typography
            variant="paragraph"
            text={translate("postStack.header.subtitle")}
          />
        </header>

        {(DOMAIN === "es" || DOMAIN === "pt" || DOMAIN === "it") && (<nav className="postStack-buttonContainer">
          <IconButton
            icon={<PhoneIcon className="postStack-icon" />}
            className="postStack-iconButton"
            label={translate("postStack.buttons.call")}
          />
          <IconButton
            icon={<CalendarIcon className="postStack-icon" />}
            className="postStack-iconButton"
            label={translate("postStack.buttons.reserve")}
            //TODO Cadenly Confirm with Jeremie

            onClick={() => setPopUpCalendly(true)}
            // eslint-disable-next-line no-undef
          />

          <PopupModal
            url="https://calendly.com/oscar-getlife"
            onModalClose={() => setPopUpCalendly(false)}
            open={popUpCalendly}
            rootElement={document.getElementById("root") as HTMLElement}
          />
          <Button
            buttonType="secondary"
            onClick={() => {
              setEmail("");
              navigate("/incidents/newticket");
            }}
          >
            {" "}
            {translate("postStack.buttons.notification")}
          </Button>
        </nav>)}
        {initialValues && (
          <main>
            <div className="postStack-mainHeader">
              <Typography variant="bold" text={translate("poststack.label.title")}/>
            </div>{" "}
            {postStackQuestionTypes.map((questionType) => {
              return (
                activeForm[questionType] && (
                  <PostStackQuestion
                    emailDisabled={emailDisabled}
                    data={selectData[questionType]}
                    fetch={petitions[questionType]}
                    initialValues={initialValues[questionType]}
                    isAnswered={isAnswered}
                    requiredFields={requiredFieldsPostStack[questionType]}
                    setIsAnswered={handleIsAnswer}
                    title={translate(`postStack.title.${questionType}`)}
                    type={questionType}
                    variantTitle="bold"
                  />
                )
              );
            })}
          </main>
        )}
        {areInitialValues(isAnswered) && (
          <footer className="postStack-footerMainContainer">
            <div>
              <Button onClick={() => navigate("/pay")}>
                {translate("postStack.buttons.continue")}
              </Button>
            </div>
          </footer>
        )}
      </div>
    </div>
  );
};

export default PostStack;
