import React, { RefObject } from "react";
import SelectGeneral from "../../../components/SelectGeneral/SelectGeneral";
import Button from "../../atoms/Buttons/ButtonBase/Button.component";
import Typography from "../../atoms/Typography/Typography.component";
import { genderOptions } from "../../../../utils/staticData";

import CalculatorController, { selectedValue } from "./Calculator.controller";

import "./Calculator.styles.scss";
import Birthdate from "../../molecules/Birthdate/Birthdate.component";
import InputGeneral from "../../molecules/InputGeneral/InputGeneral.component";
import RadioGroup from "../../molecules/RadioGroup/RadioGroup.component";
import Checkbox from "../../atoms/Input/CheckBox/Checkbox.component";

import { errorMessage } from "../../../../utils/error";

import Switch from "../../atoms/Input/Switch/Switch.component";

import NumberAnimated from "../../molecules/NumberAnimate/NumberAnimate.component";
import MinusIcon from "../../atoms/Icons/MinusIcon.component";
import numberFormat from "../../../../utils/numberFormat";
import PlusIcon from "../../atoms/Icons/PlusIcon.component";
import IconButton from "../../atoms/Buttons/IconButton/IconButton.component";
import InfoMessage from "../../atoms/InfoMessage/InfoMessage.component";

export interface CalculatorProps {
  brokerId: string;
}

const Calculator: React.FC<CalculatorProps> = ({ brokerId }) => {
  const {
    areEmptyValues,
    capital,
    capitalSelect,
    error,
    formLimits,
    handleChangeCapital,
    handleChangeSwitch,
    handleSubmitStep2,
    insurancePrice,
    limits,
    navigateToQuestions,
    pdfElement,
    pdfUrl,
    stepCalculator,
    setStepCalculator,
    setValue,
    traits,
    translate,
    values,
  } = CalculatorController({ brokerId });

  return (
    <article className="section-calculator">
      <Typography
        variant="h1"
        text={translate("landing.calculator.title")}
        className="title"
      />
      <div className="calculator--container">
        <div className="steps-container">
          <Button
            disabled={stepCalculator < 1}
            onClick={() => {
              setStepCalculator(1);
              if ("checkedIPA" in values) {
                delete values.checkedIPA;
              }
            }}
          >
            Paso 1
          </Button>
          <Button
            disabled={stepCalculator < 2}
            onClick={() => {
              setStepCalculator(2);
              if ("checkedIPA" in values) {
                delete values.checkedIPA;
              }
            }}
          >
            Paso 2
          </Button>
          <Button
            disabled={stepCalculator < 3}
            onClick={() => setStepCalculator(3)}
          >
            Tu Precio
          </Button>
        </div>
        <div className="calculator">
          {stepCalculator === 1 && (
            <>
              <div
                className={`calculator--step1 ${
                  stepCalculator === 1 && "animationIn"
                }`}
              >
                <Birthdate
                  variantTitle="h3"
                  title={translate("landing.calculator.birthdate.title")}
                  values={formLimits.values}
                  setValue={formLimits.setValue}
                  error={formLimits.error}
                />
                <div className="input-zipcode">
                  <InputGeneral
                    className="input"
                    classNameLabel="calculatorSubtitle"
                    editValue={formLimits.values["zipCode"]}
                    error={errorMessage(formLimits.error, "zipCode")}
                    id="input-zipCode"
                    isNumber
                    type="number"
                    errorType="landing"
                    label={translate("landing.calculator.zipcode.label")}
                    name="zipCode"
                    placeholder={"27010"}
                    setter={(newValue) =>
                      formLimits.setValue("zipCode", newValue)
                    }
                  />
                </div>
              </div>
              <Button
                buttonType="primary"
                className={`calculator--button ${
                  !areEmptyValues(formLimits.values) &&
                  "calculator--button__disabled"
                }`}
                disabled={!areEmptyValues(formLimits.values)}
                onClick={() => formLimits.handleSubmit()}
              >
                {translate("landing.calculator.button.continue")}
              </Button>
            </>
          )}
          {stepCalculator === 2 && (
            <>
              <div
                className={`calculator--step2  ${
                  stepCalculator === 2 && "animationIn"
                }`}
              >
                <div className="calculator--step2__row1">
                  <InputGeneral
                    className="input"
                    classNameLabel="calculatorSubtitle"
                    editValue={values["phone"]}
                    error={errorMessage(error, "phone")}
                    id="input-phone"
                    isNumber
                    errorType="landing"
                    type="number"
                    label={translate("landing.calculator.telephone.label")}
                    name="phone"
                    placeholder="666666666"
                    setter={(newValue) => setValue("phone", newValue)}
                  />
                  <InputGeneral
                    className="input"
                    classNameLabel="calculatorSubtitle"
                    editValue={values["email"]}
                    error={errorMessage(error, "email")}
                    errorType="landing"
                    id="input-email"
                    label={translate("landing.calculator.email.label")}
                    name="email"
                    type="email"
                    placeholder="Ej. hola@correo.es"
                    setter={(newValue) => setValue("email", newValue)}
                  />
                  <div className="gender">
                    <Typography
                      variant="h3"
                      text={translate("landing.calculator.gender.title")}
                      className="gender--title"
                    />
                    <RadioGroup
                      arrayRadio={genderOptions(translate)}
                      classNameContainer="gender--container"
                      className="input-radio"
                      error={errorMessage(error, "gender")}
                      editValue={values.gender}
                      errorType="landing"
                      setter={(newValue: string) =>
                        setValue("gender", newValue)
                      }
                    />
                  </div>

                  <SelectGeneral
                    className="capital"
                    disabled={false}
                    editValue={selectedValue(values["capital"], capitalSelect)}
                    error={errorMessage(error, "capital")}
                    errorType="landing"
                    handleValues={(newValue: string) =>
                      setValue("capital", newValue)
                    }
                    isMultiple={false}
                    label={translate("landing.calculator.capital.title")}
                    placeholder={translate(
                      "landing.calculator.capital.placeholder"
                    )}
                    toMap={capitalSelect}
                    width={{ width: "41px" }}
                  />
                </div>
                <div className="calculator--step2__row2">
                  <Checkbox
                    label={
                      <p>
                        {translate("landing.calculator.checkbox")}{" "}
                        <a
                          target="_blank"
                          href="https://life5.es/politica-privacidad/"
                          rel="noopener noreferrer"
                        >
                          {translate("landing.calculator.link")}
                        </a>
                      </p>
                    }
                    value={values["checked"]}
                    checked={values["checked"]}
                    checkmark
                    id="checkbox-landing-calculator"
                    onChange={(newValue) =>
                      setValue("checked", newValue.target.checked)
                    }
                  />
                  {error && (
                    <InfoMessage
                      message={errorMessage(error, "checked")}
                      type="landing"
                    />
                  )}
                </div>
              </div>
              <Button
                buttonType="primary"
                disabled={!areEmptyValues(values)}
                onClick={() => handleSubmitStep2()}
                className={`calculator--button ${
                  !areEmptyValues(values) && "calculator--button__disabled"
                }`}
              >
                {translate("landing.calculator.button.continue")}
              </Button>{" "}
            </>
          )}
          {stepCalculator === 3 && (
            <>
              <div
                className={`calculator--step3 ${
                  stepCalculator === 3 && "animationIn"
                }`}
              >
                <div className="calculator--step3__row1">
                  <Switch
                    id="landing-calculator-switch"
                    labelRight="Fallecimiento + Invalidez(IPA)"
                    labelLeft="Fallecimiento"
                    disabled={!traits.DISABILITY}
                    checked={values["checkedIPA"]}
                    onChange={(newValue) => {
                      handleChangeSwitch(newValue);
                    }}
                  />
                </div>
                <div className="calculator--step3__row2">
                  <div className="show--results">
                    <Typography
                      variant="paragraph"
                      text="Tu precio mensual va desde"
                      className="monthly-text"
                    />
                    <Typography variant="paragraph" text="" className="price">
                      <NumberAnimated
                        format
                        end={Math.round(
                          insurancePrice
                            ? insurancePrice[
                                values.checkedIPA ? "premiumFrom" : "basicFrom"
                              ]
                            : 0
                        )}
                      />
                      {" - "}
                      <NumberAnimated
                        format
                        end={Math.round(
                          insurancePrice
                            ? insurancePrice[
                                values.checkedIPA ? "premiumTo" : "basicTo"
                              ]
                            : 0
                        )}
                      />
                    </Typography>
                    <Typography
                      variant="paragraph"
                      text=""
                      className="price-anual"
                    >
                      <NumberAnimated
                        format
                        end={Math.round(
                          (insurancePrice
                            ? insurancePrice[
                                values.checkedIPA ? "premiumFrom" : "basicFrom"
                              ]
                            : 0) * 12
                        )}
                      />
                      /año
                    </Typography>
                  </div>
                  <div className="recalculateCapital">
                    <Typography
                      className="titleCapital"
                      variant="paragraph"
                      text="Modifica tu capital para adaptar el precio mensual"
                    />
                    <Typography
                      variant="span"
                      text=""
                      className="change-capital"
                    >
                      <IconButton
                        className="landing-icon-button"
                        icon={<MinusIcon colorRect="#f5c4dc" color="#423ef4" />}
                        disabled={capital === 0 || limits.minCapital > capital}
                        onClick={() => handleChangeCapital("minus")}
                      />
                      <Typography
                        variant="paragraph"
                        text={numberFormat(capital, "capital") as string}
                        className="capital-text"
                      />
                      <IconButton
                        className="landing-icon-button"
                        icon={<PlusIcon color="#f5c4dc" colorPath="#423ef4" />}
                        disabled={capital === 0 || limits.maxCapital < capital}
                        onClick={() => handleChangeCapital("plus")}
                      />
                    </Typography>

                    <a
                      ref={pdfElement as RefObject<HTMLAnchorElement>}
                      href={pdfUrl}
                      target="_blank"
                      className={`button-download`}
                      rel="noopener noreferrer"
                      id="downloadPDF"
                    >
                      {translate("landing.calculator.button.downloadPDF")}
                    </a>
                  </div>
                </div>
              </div>
              <Button
                onClick={() => navigateToQuestions()}
                className={`button-contract`}
              >
                {translate("landing.buttons.contract")}
              </Button>
            </>
          )}
        </div>
      </div>
    </article>
  );
};

export default Calculator;
