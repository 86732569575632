const calcListYearOptions = function () {
    const today = new Date();
    const thisYear = today.getFullYear();
    const minYear = thisYear - 17;

    const years = [...Array(65).keys()];
    const optionsYears = [];

    years.forEach((element) => {
        let year = element + 1945;
        if ( year <= minYear) {
            optionsYears.push({
                value: String(year),
                label: String(year),
            })
        }
    });

    return optionsYears;

}

export default calcListYearOptions;